import { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import { ROUTE_NAMES } from '#/constants';
import Colors from '#/styles/colors';

const SIDEBAR_WIDTH = 200;

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: SIDEBAR_WIDTH,
    background: theme.palette.primary.main,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  itemText: {
    fontWeight: 'bold',
    color: Colors.white,
  },
}));

const itemsForTutor = [
  { name: 'Messages', path: ROUTE_NAMES.MESSAGES },
  { name: 'Profile', path: ROUTE_NAMES.PROFILE },
];

const itemsForContentManager = [
  { name: 'Content', path: ROUTE_NAMES.CONTENTS },
  { name: 'Profile', path: ROUTE_NAMES.PROFILE },
];

const Sidebar = ({ isTutor }: { isTutor: boolean }): JSX.Element => {
  const classes = useStyles();
  const items = useMemo(() => (isTutor ? itemsForTutor : itemsForContentManager), [isTutor]);

  return (
    <nav className={classes.sidebar}>
      <ul className={classes.list}>
        {items.map((item) => (
          <li key={item.path}>
            <Link component={RouterLink} to={item.path}>
              <Button className={classes.item} fullWidth>
                <Typography className={classes.itemText}>
                  {item.name}
                </Typography>
              </Button>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Sidebar;
