import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import JSUtility from '#/util/JSUtility';

const firebaseDevConfig = {
  apiKey: 'AIzaSyCwSdTaUtA4TMEzJvpyobDcciIeMqNMp6M',
  authDomain: 'mimiking-dev.firebaseapp.com',
  databaseURL: 'https://mimiking-dev.firebaseio.com',
  projectId: 'mimiking-dev',
  storageBucket: 'mimiking-dev.appspot.com',
  messagingSenderId: '284727263546',
  appId: '1:284727263546:web:8cad810ff7119051da1003',
  measurementId: 'G-F30R0F8RVS',
};

const firebaseProdConfig = {
  apiKey: 'AIzaSyB6KyudGfo-DS-YPZsVos9Oy1FYBihBH18',
  authDomain: 'mimiking-8d91c.firebaseapp.com',
  databaseURL: 'https://mimiking-8d91c.firebaseio.com',
  projectId: 'mimiking-8d91c',
  storageBucket: 'mimiking-8d91c.appspot.com',
  messagingSenderId: '322546126495',
};

const isDevelopement = JSUtility.isDevelopment();
const firebaseApp = firebase.initializeApp(isDevelopement
  ? firebaseDevConfig
  : firebaseProdConfig);

const Firebase = firebase.app.length === 0
  ? firebaseApp
  : firebase.app();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

export const { EmailAuthProvider } = firebase.auth;

export default Firebase;
