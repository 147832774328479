import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

import {
  Option,
  ContentTabs,
  ProfileTabs,
} from '#/types';
import Colors from '#/styles/colors';

const useStyles = makeStyles({
  root: {
    borderBottom: `1px inset ${Colors.gray}`,
  },
});

interface CustomTabProps<TabType> {
  defaultTab: TabType;
  tabs: Option<TabType>[];
  tabName: string;
  onChangeTab: (e: unknown, newValue: TabType) => void;
}

type ParentTabOptionTypes = ContentTabs | ProfileTabs;

const CustomTab = <TabType extends ParentTabOptionTypes>({
  defaultTab,
  tabs,
  tabName,
  onChangeTab,
}: CustomTabProps<TabType>): JSX.Element => {
  const classes = useStyles();

  return (
    <Tabs
      className={classes.root}
      value={defaultTab}
      onChange={onChangeTab}
      aria-label={`${tabName} tabs`}
      textColor="primary"
      indicatorColor="primary"
    >
      {tabs.map(
        ({ value, label }) => (
          <Tab key={label} value={value} label={label} />
        ),
      )}
    </Tabs>
  );
};

export default CustomTab;
