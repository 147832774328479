import {
  useCallback,
  useState,
} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useAuthContext } from '#/contexts/AuthContext';
import {
  KEYBOARD_EVENT_CODE_ENTER,
  FIREBASE_AUTH_ERROR_CODE,
} from '#/constants';
import Loading from '../Loading';
import { FirebaseError } from '#/types/index';
import {
  Severity,
  useAlertContext,
} from '#/contexts/AlertContext';

const useStyles = makeStyles({
  container: {
    width: '30rem',
  },
  buttonGroup: {
    display: 'flex',
    gap: '1.5rem',
    margin: '1rem 0rem',
  },
  button: {
    width: '10rem',
    padding: '1rem 2rem',
  },
});

const ProfilePassword = (): JSX.Element => {
  const classes = useStyles();
  const { openAlert } = useAlertContext();

  const { user, changePassword, logout } = useAuthContext();
  const [currentPassword, setCurrentPassword] = useState('');
  const [hasCurrentPasswordError, setHasCurrentPasswordError] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hasConfirmPasswordError, setHasConfirmPasswordError] = useState(false);

  const [
    profilePasswordError,
    setProfilePasswordError,
  ] = useState<FirebaseError | Error | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const onChangeInput = useCallback((e) => {
    const { name, value } = e.target;

    if (name === 'currentPassword') {
      setCurrentPassword(value);
    }
    if (name === 'newPassword') {
      setNewPassword(value);
    }
    if (name === 'confirmPassword') {
      setConfirmPassword(value);
      setHasConfirmPasswordError(value !== newPassword);
    }
  }, [newPassword]);

  const onClickSubmit = useCallback(async () => {
    if (changePassword == null || logout == null) {
      return;
    }

    if (hasConfirmPasswordError) {
      return;
    }

    setIsLoading(true);
    try {
      await changePassword(currentPassword, newPassword);
      openAlert(Severity.SUCCESS, 'Password has been successfully changed.');
      logout();
    } catch (err) {
      setHasCurrentPasswordError(err.code === FIREBASE_AUTH_ERROR_CODE.WRONG_PASSWORD);
      setProfilePasswordError(err);
    } finally {
      setIsLoading(false);
    }
  }, [
    openAlert,
    changePassword,
    logout,
    currentPassword,
    newPassword,
    hasConfirmPasswordError,
  ]);

  const onClickCancel = useCallback(async () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  }, []);

  const onKeyDownEnter = useCallback((e: React.KeyboardEvent) => {
    if (e.code !== KEYBOARD_EVENT_CODE_ENTER) {
      return;
    }

    e.preventDefault();
    onClickSubmit();
  }, [onClickSubmit]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <FormControl
      className={classes.container}
      onChange={onChangeInput}
      onKeyDown={onKeyDownEnter}
    >
      <TextField
        variant="outlined"
        margin="normal"
        disabled
        fullWidth
        name="email"
        label="Email"
        type="email"
        id="email"
        // user always FirebaseUser in this component but there is type error possibly null
        value={user?.email ?? ''}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="password"
        label="Current Password"
        name="currentPassword"
        id="currentPassword"
        error={hasCurrentPasswordError}
        helperText={hasCurrentPasswordError && 'Please check password'}
        value={currentPassword}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="newPassword"
        label="New Password"
        type="password"
        id="newPassword"
        value={newPassword}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="confirmPassword"
        label="Confirm Password"
        type="password"
        id="confirmPassword"
        error={hasConfirmPasswordError}
        helperText={hasConfirmPasswordError && 'Passwords must match'}
        value={confirmPassword}
      />
      <Typography color="error">
        {profilePasswordError?.message ?? ''}
      </Typography>
      <div className={classes.buttonGroup}>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={onClickSubmit}
        >
          <Typography>Update</Typography>
        </Button>
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          onClick={onClickCancel}
        >
          <Typography>Cancel</Typography>
        </Button>
      </div>
    </FormControl>
  );
};

export default ProfilePassword;
