const ROUTE_NAMES = {
  BASE: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',

  PROFILE: '/profile',

  MESSAGES: '/messages',
  MESSAGE_ID: '/messages/:channelId',

  CONTENTS: '/contents',
  CONTENT: '/contents/:contentId',
  CONTENT_ADD: '/contents/add',
};

export default ROUTE_NAMES;
