import {
  ProfileTabs,
  Option,
} from '#/types';
import CustomTab from '../CustomTab';

interface ProfileTabProps {
  defaultTab: ProfileTabs;
  onChangeTab: (e: unknown, newValue: ProfileTabs) => void;
}

const ProfileTabItems: Option<ProfileTabs>[] = [
  { value: ProfileTabs.TIME_ZONE, label: 'Time Zone' },
  { value: ProfileTabs.PASSWORD, label: 'Password' },
];

const ProfileTab = ({ defaultTab, onChangeTab }: ProfileTabProps): JSX.Element => (
  <CustomTab
    defaultTab={defaultTab}
    tabs={ProfileTabItems}
    tabName="profile"
    onChangeTab={onChangeTab}
  />
);

export default ProfileTab;
