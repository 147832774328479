import firebase from 'firebase';

import dayjs from '#/modules/ExtendedDayjs';

export type FirestoreTimestamp = firebase.firestore.Timestamp;
export type FirebaseUser = firebase.User;

export interface TableData {
  id: string;
}

export interface MessageTableData extends TableData {
  time: dayjs.Dayjs;
  student: string;
  recentMessage: string;
  answerReceived: boolean;
  didFeedback: boolean;
}

export interface ContentTableData extends TableData {
  createdAt: dayjs.Dayjs;
  topic: string;
  level: string;
  questionNumber: number;
  question: string;
  sent: number;
  answered: number;
  completed: number;
  published: boolean;
}

export interface HeadCell<Data> {
  id: keyof Data;
  label: string;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
}

export type Option<T> = {
  value: T;
  label: string;
};

export enum ContentPublishOption {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

export enum ContentTabs {
  ALL = 'ALL',
  PUBLISHED = 'PUBLISHED',
  DRAFTS = 'DRAFTS',
}

export enum ProfileTabs {
  TIME_ZONE = 'TIME_ZONE',
  PASSWORD = 'PASSWORD',
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export enum CustomMessageType {
  QUESTION = 'question', // From redkiwi-server scheduled function (Server will send)
  REVIEW = 'review', // Overall review of student's answer (Tutor will send)
  FEEDBACK = 'feedback', // Comments for student's answer each sentence and use metaArrays shape of DMP (JSUtility) (Tutor will send)
  ANSWER = 'answer', // Student's message replying to question (Student will send)
  TEXT = 'text', // Just message no need to classify.
}

export type ContentAddData = {
  createdAt: dayjs.Dayjs;
  published: ContentPublishOption;
  topic: string;
  level: string;
  question: string;
};

export type ContentEditData = {
  id: string;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;
  published: ContentPublishOption;
  topic: string;
  level: string;
  questionNumber: number;
  question: string;
  sent: number;
  answered: number;
  completed: number;
};

export type ContentServerData = {
  id: string;
  contentNumber?: number;
  topicId: string;
  levelId: string;
  question: string;
  published: boolean;
  sentCount: number;
  answeredCount: number;
  createdAt: string;
  updatedAt: string;
};

export type OptionsToRender = {
  topic: Option<string>[];
  level: Option<string>[];
  status: Option<ContentPublishOption>[];
};

export interface ChatMessageProps {
  message: SendBird.UserMessage;
}

export enum ChatMessageAlign {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface FirebaseError {
  code: string;
  message: string;
}
