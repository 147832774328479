const Colors = {
  redkiwiRed: '#ee4e48',
  black: '#000',
  white: '#fff',
  gray: '#bbb',
  darkGray: '#555',
  red: '#fa5252', // openColor.red[6]
  green: '#2f9e44', // openColor.green[8]
  mint: '#03DAC6',

  tutorColor: '#107BA8',
  contentManagerColor: '#21293f',
};

export default Colors;
