import {
  useCallback,
} from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';

import {
  MessageTableData,
  HeadCell,
  Order,
} from '#/types';
import CustomTable from '../CustomTable';
import { ROUTE_NAMES } from '#/constants';
import Colors from '#/styles/colors';

interface MessageDataProps {
  data: MessageTableData[];
}

const headCells: HeadCell<MessageTableData>[] = [
  { id: 'time', label: 'Time', align: 'left' },
  { id: 'student', label: 'Student' },
  { id: 'recentMessage', label: 'Recent Message', align: 'left' },
  { id: 'answerReceived', label: 'Answer' },
  { id: 'didFeedback', label: 'Feedback' },
];

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
  },
  messageCell: {
    position: 'relative',
  },
  message: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: 16,
  },
  redText: {
    color: Colors.redkiwiRed,
  },
  grayText: {
    color: Colors.gray,
  },
});

const MessagesTable = ({ data }: MessageDataProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const onClickRow = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    const dataId = e.currentTarget.getAttribute('data-id');
    const dataName = e.currentTarget.getAttribute('data-name');

    history.push(`${ROUTE_NAMES.MESSAGES}/${dataId}?displayName=${dataName}`);
  }, [history]);

  const renderItem = useCallback((row: MessageTableData) => (
    <TableRow
      className={classes.row}
      key={row.id}
      hover
      data-name={row.student}
      data-id={row.id}
      onClick={onClickRow}
      tabIndex={-1}
    >
      <TableCell align="left" width="180px">
        <Typography variant="body2">
          {row.time.format('MMM D, YYYY HH:mm:ss')}
        </Typography>
      </TableCell>
      <TableCell align="center" width="120px">
        <Typography variant="body2">
          {row.student}
        </Typography>
      </TableCell>
      <TableCell className={classes.messageCell} align="left">
        <Typography className={classes.message} variant="body2">
          {row.recentMessage}
        </Typography>
      </TableCell>
      <TableCell align="center" width="120px">
        <Typography
          className={row.answerReceived ? '' : classes.grayText}
          variant="body2"
        >
          {row.answerReceived ? 'Received' : 'Not Received'}
        </Typography>
      </TableCell>
      <TableCell align="center" width="120px">
        <Typography
          className={
            !row.answerReceived
              ? classes.grayText
              : row.didFeedback
                ? ''
                : classes.redText
          }
          variant="body2"
        >
          {
            !row.answerReceived
              ? '-'
              : row.didFeedback
                ? 'Sent'
                : 'Not Sent'
          }
        </Typography>
      </TableCell>
    </TableRow>
  ), [classes, onClickRow]);

  return (
    <CustomTable
      head={headCells}
      data={data}
      defaultOrder={Order.DESC}
      defaultOrderBy="time"
      renderItem={renderItem}
    />
  );
};

export default MessagesTable;
