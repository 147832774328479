import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Header from './Header';
import Sidebar from './Sidebar';

const useStyles = makeStyles({
  template: {
    display: 'flex',
    flex: 1,
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  main: {
    padding: 30,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    flex: 1,
  },
});

const Template = ({
  isTutor,
  children,
}: { isTutor: boolean, children: JSX.Element }): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.template}>
      <Sidebar isTutor={isTutor} />
      <div className={classes.right}>
        <Header isTutor={isTutor} />
        <Divider />
        <main className={classes.main}>
          {children}
        </main>
      </div>
    </div>
  );
};

export default Template;
