import React from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles({
  searchContainer: {
    maxWidth: 500,
    display: 'flex',
    margin: '1rem 0',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
});

interface ContentSearchProps {
  searchKeyword: string;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ContentsSearch = ({ searchKeyword, onChangeSearch }: ContentSearchProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.searchContainer}>
      <OutlinedInput
        className={classes.input}
        value={searchKeyword}
        onChange={onChangeSearch}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search contents' }}
        endAdornment={(
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
        )}
      />
    </div>
  );
};

export default ContentsSearch;
