import {
  useEffect,
  useCallback,
  useState,
  useRef,
} from 'react';

import dayjs from '#/modules/ExtendedDayjs';
import SendBird from '#/modules/SendBird';
import MessagesTable from '#/components/messages/MessagesTable';
import Loading from '#/components/Loading';
import {
  MessageTableData,
  CustomMessageType,
} from '#/types';
import { useSendBirdContext } from '#/contexts/SendBirdContext';
import JSUtility from '#/util/JSUtility';
import CustomPage from '#/components/CustomPage';
import {
  useAlertContext,
  Severity,
} from '#/contexts/AlertContext';

const getDisplaySenderTextByMessageType = (messageType: string) => {
  switch (messageType) {
    case CustomMessageType.QUESTION:
      return 'System';
    case CustomMessageType.ANSWER:
      return 'Student';
    case CustomMessageType.FEEDBACK:
      return 'Tutor';
    default: return 'Unknown';
  }
};

const convertChannelDataToMessageTableData = (
  channel: SendBird.GroupChannel, sbUser: SendBird.User,
) => {
  const { lastMessage, members } = channel;
  if (lastMessage.messageType !== 'user') return null;

  const studentNickname = members
    .filter(((member) => member.userId !== sbUser.userId))
    .map((user) => user.nickname)
    .join(' ');

  const now = new Date();
  const isLastMessageInSameUnit = JSUtility.getIsSameUnitPeriod(
    new Date(lastMessage.createdAt),
    now,
  );

  return ({
    id: lastMessage.channelUrl,
    student: studentNickname,
    time: dayjs(lastMessage.createdAt),
    recentMessage: `${getDisplaySenderTextByMessageType(lastMessage.customType)}: ${lastMessage.message}`,
    answerReceived: (
      isLastMessageInSameUnit
      && (
        lastMessage.customType === CustomMessageType.ANSWER
        // "lastMessage's type is FEEDBACK"
        // meaning already got answer and did feedback
        || lastMessage.customType === CustomMessageType.FEEDBACK
      )
    ),
    didFeedback: isLastMessageInSameUnit && lastMessage.customType === CustomMessageType.FEEDBACK,
  });
};

const MessagesPage = (): JSX.Element => {
  const { sbUser } = useSendBirdContext();
  const { openAlert } = useAlertContext();

  const [messageData, setMessageData] = useState<MessageTableData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const createChannelListQuery = useCallback(() => {
    const listQuery = SendBird.GroupChannel.createMyGroupChannelListQuery();
    listQuery.includeEmpty = false;
    listQuery.order = 'latest_last_message';
    // Currently tutor's limit capacity of matched student is 10.
    listQuery.limit = 100; // The value of pagination limit could be set up to 100.

    return listQuery;
  }, []);

  const groupChannelListQueryRef = useRef<SendBird.GroupChannelListQuery>(createChannelListQuery());
  const getChannels = useCallback(async () => {
    if (!groupChannelListQueryRef.current.hasNext) return [];

    const groupChannels = await groupChannelListQueryRef.current.next();
    return groupChannels;
  }, []);

  const makeTableDataFromChannels = useCallback((channels: SendBird.GroupChannel[]) => {
    if (sbUser == null) return;

    const newMessageData: MessageTableData[] = (
      channels
        .map((channel) => convertChannelDataToMessageTableData(channel, sbUser))
        .filter(
          (messageTableData): messageTableData is MessageTableData => (messageTableData != null),
        )
    );

    setMessageData(newMessageData);
  }, [sbUser]);

  useEffect(() => {
    const getMessengerData = async () => {
      setIsLoading(true);
      try {
        const channels = await getChannels();
        makeTableDataFromChannels(channels);
      } catch (err) {
        openAlert(Severity.ERROR, err);
      } finally {
        setIsLoading(false);
      }
    };

    if (sbUser == null) return;

    getMessengerData();
  }, [openAlert,
    sbUser, getChannels, makeTableDataFromChannels]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CustomPage>
      <MessagesTable data={messageData} />
    </CustomPage>
  );
};

export default MessagesPage;
