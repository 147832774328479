import {
  BrowserRouter,
} from 'react-router-dom';

import { useAuthContext } from '../contexts/AuthContext';
import PublicRouter from './PublicRouter';
import PrivateRouter from './PrivateRouter';
import Loading from '#/components/Loading';

const RouteIndex = (): JSX.Element => {
  const { isAuthenticated, isUserLoaded } = useAuthContext();

  if (!isUserLoaded) {
    return (
      <Loading />
    );
  }

  return (
    <BrowserRouter>
      {!isAuthenticated
        ? <PublicRouter />
        : <PrivateRouter />}
    </BrowserRouter>
  );
};

export default RouteIndex;
