import { useMemo } from 'react';

import {
  ContentTabs,
  Option,
} from '#/types';
import CustomTab from '../CustomTab';

interface ContentTabProps {
  defaultTab: ContentTabs;
  onChangeTab: (_: unknown, newOption: ContentTabs) => void;
  tabInfo: Record<ContentTabs, number>;
}

const ContentsTab = ({ defaultTab, onChangeTab, tabInfo }: ContentTabProps): JSX.Element => {
  const ContentTabItems: Option<ContentTabs>[] = useMemo(() => ([
    { value: ContentTabs.ALL, label: `All ${tabInfo.ALL}` },
    { value: ContentTabs.PUBLISHED, label: `Published ${tabInfo.PUBLISHED}` },
    { value: ContentTabs.DRAFTS, label: `Drafts ${tabInfo.DRAFTS}` },
  ]), [tabInfo]);

  return (
    <CustomTab
      defaultTab={defaultTab}
      tabs={ContentTabItems}
      tabName="contents"
      onChangeTab={onChangeTab}
    />
  );
};

export default ContentsTab;
