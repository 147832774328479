import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
  titleArea: {
    fontSize: 16,
  },
});

interface MessageDetailItemProps {
  title: string;
  body?: JSX.Element;
  titleOption?: JSX.Element | string;
}

const MessageDetailItem = ({ title, body, titleOption }: MessageDetailItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <li>
      <span className={classes.titleArea}>
        <Typography className={classes.title} component="span">
          {`${title} `}
        </Typography>
        {titleOption}
      </span>
      <div>
        {body}
      </div>
    </li>
  );
};

export default MessageDetailItem;
