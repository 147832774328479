import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  CORRECT_SENTENCE,
  EXPLANATION,
} from '#/constants';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
});

interface MessageFeedbackProps {
  original: string;
  correctSentence: string;
  correctSentenceErrorText: string;
  explanation: string;
  explanationErrorText: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  index: number;
}

const MessageFeedback = ({
  original,
  correctSentence,
  correctSentenceErrorText,
  explanation,
  explanationErrorText,
  onChange,
  index,
}: MessageFeedbackProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        name={`${CORRECT_SENTENCE}-${index}`}
        value={correctSentence}
        label={original}
        onChange={onChange}
        error={correctSentenceErrorText.length !== 0}
        helperText={correctSentenceErrorText}
        variant="outlined"
        fullWidth
        multiline
        required
      />
      <TextField
        name={`${EXPLANATION}-${index}`}
        placeholder="Additional explanation (Optional, detailed feedback recommended)"
        value={explanation}
        onChange={onChange}
        error={explanationErrorText.length !== 0}
        helperText={explanationErrorText}
        variant="outlined"
        fullWidth
        multiline
      />
    </div>
  );
};

export default MessageFeedback;
