import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import CustomBreadcrumbs from './CustomBreadcrumbs';

const useStyles = makeStyles({
  customPage: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
});

interface CustomPageProps {
  children: JSX.Element | JSX.Element[];
  pathname?: string;
  className?: string;
  breadcrumbsComponent?: JSX.Element;
  hideBreadcrumbs?: boolean;
}

const CustomPage = ({
  pathname,
  children,
  className,
  breadcrumbsComponent,
  hideBreadcrumbs = false,
}: CustomPageProps): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <section className={classes.customPage}>
      {!hideBreadcrumbs
        && (
          <CustomBreadcrumbs
            pathname={pathname ?? location.pathname}
            rightComponent={breadcrumbsComponent}
          />
        )}
      <div className={className}>
        {children}
      </div>
    </section>
  );
};

export default CustomPage;
