import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import SendBird from '#/modules/SendBird';
import MessageDetailItems from '#/components/messages/MessageDetailItems';
import { useSendBirdContext } from '#/contexts/SendBirdContext';
import Loading from '#/components/Loading';
import { CustomMessageType } from '#/types';
import JSUtility from '#/util/JSUtility';
import MessagesLog from '#/components/messages/MessagesLog';
import CustomPage from '#/components/CustomPage';
import useDisplayName from '#/hooks/useDisplayName';
import {
  useAlertContext,
  Severity,
} from '#/contexts/AlertContext';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    gap: 40,
  },
  feedbackContainer: {
    display: 'flex',
    flexBasis: '60%',
    alignItems: 'flex-start',
  },
  messageLogContainer: {
    display: 'flex',
    flexBasis: '40%',
  },
});

const MessageSendPage = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const displayName = useDisplayName();

  const { sbUser } = useSendBirdContext();
  const { openAlert } = useAlertContext();

  const { channelId } = useParams<Record<'channelId', string>>();
  const [messages, setMessages] = useState<SendBird.UserMessage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [messageDetailItemHeight, setMessageDetailItemHeight] = useState(0);

  const createMessageQuery = useCallback((groupChannel: SendBird.GroupChannel) => {
    const messageQuery = groupChannel.createPreviousMessageListQuery();
    messageQuery.reverse = true; // Latest comes at first and the earliest at last
    messageQuery.includeMetaArray = true;
    // Only get SendBird.UserMessage
    messageQuery.messageTypeFilter = 'MESG';
    // Mistype customTypesFilter for this version. Array<string> is right but it's just string.
    // messageQuery.customTypesFilter = [CustomMessageType.QUESTION, CustomMessageType.ANSWER];
    messageQuery.limit = 50;
    return messageQuery;
  }, []);
  const messageQueryRef = useRef<SendBird.PreviousMessageListQuery>();
  const groupChannelRef = useRef<SendBird.GroupChannel>();

  const getChannel = useCallback(async () => {
    try {
      const groupChannel = await SendBird.GroupChannel.getChannel(channelId);
      groupChannelRef.current = groupChannel;
      return groupChannel;
    } catch (err) {
      // When there is no channel as id ${channelId}
      history.goBack();
      throw err;
    }
  }, [channelId, history]);

  const loadMessages = useCallback(async (channel: SendBird.GroupChannel) => {
    if (messageQueryRef.current == null) {
      messageQueryRef.current = createMessageQuery(channel);
    }

    if (!messageQueryRef.current.hasMore) return;

    /**
       * It describes no effect "await" but it needs.
       * Or not, the sbMessages will be Promise {<pending>}
       */
    const sbMessages = await messageQueryRef.current.load();

    const validSbMessages: SendBird.UserMessage[] = sbMessages.filter(
      // User-Defined type guard feature "X is Y"
      // https://stackoverflow.com/questions/43010737/way-to-tell-typescript-compiler-array-prototype-filter-removes-certain-types-fro/54317362
      (sbMessage): sbMessage is SendBird.UserMessage => sbMessage.isUserMessage(),
    );

    setMessages((prevMessages) => ([...prevMessages, ...validSbMessages]));
  }, [createMessageQuery]);

  useEffect(() => {
    const getMessage = async () => {
      setIsLoading(true);
      try {
        const channel = await getChannel();
        await loadMessages(channel);
      } catch (err) {
        openAlert(Severity.ERROR, err);
      } finally {
        setIsLoading(false);
      }
    };

    if (sbUser == null) return;

    getMessage();
  }, [openAlert, sbUser, getChannel, loadMessages]);

  const lastQuestion = useMemo(() => {
    if (sbUser == null) return null;

    // messages is sorted by latest message
    const latestQuestion = messages.find(
      (message) => (
        sbUser.userId === message.sender.userId
        && message.customType === CustomMessageType.QUESTION
      ),
    );

    if (latestQuestion === undefined) return undefined;

    return {
      message: latestQuestion.message,
      sentAt: new Date(latestQuestion.createdAt),
    };
  }, [sbUser, messages]);

  const lastStudentAnswer = useMemo(() => {
    if (sbUser == null) return null;

    // messages is sorted by latest message
    const latestStudentAnswer = messages.find(
      (message) => (
        sbUser.userId !== message.sender.userId
        && message.customType === CustomMessageType.ANSWER
      ),
    );

    if (latestStudentAnswer === undefined) return undefined;

    return {
      message: latestStudentAnswer.message,
      receivedAt: new Date(latestStudentAnswer.createdAt),
    };
  }, [sbUser, messages]);

  const lastFeedback = useMemo(() => {
    if (sbUser == null) return null;

    const latestFeedback = messages.find(
      (message) => (
        sbUser.userId === message.sender.userId
        && message.customType === CustomMessageType.FEEDBACK
      ),
    );

    if (latestFeedback === undefined) return undefined;

    const didFeedbackAt = new Date(latestFeedback.createdAt);
    const now = new Date();
    const didFeedbackInSameUnit = JSUtility.getIsSameUnitPeriod(didFeedbackAt, now);

    return { didFeedbackInSameUnit, didFeedbackAt };
  }, [sbUser, messages]);

  const loadMore = useCallback(async () => {
    if (groupChannelRef.current == null) return;

    await loadMessages(groupChannelRef.current);
  }, [loadMessages]);

  if (isLoading || lastQuestion === null || lastStudentAnswer === null || lastFeedback === null) {
    // When sbUser isn't loaded or loading.
    return <Loading />;
  }

  return (
    <CustomPage className={classes.root} pathname={`messages/${displayName}`}>
      <div className={classes.messageLogContainer}>
        <MessagesLog
          messages={messages}
          messageDetailItemHeight={messageDetailItemHeight}
          loadMore={loadMore}
        />
      </div>
      <div className={classes.feedbackContainer}>
        <MessageDetailItems
          setMessages={setMessages}
          question={lastQuestion}
          setMessageDetailItemHeight={setMessageDetailItemHeight}
          studentAnswer={lastStudentAnswer}
          lastFeedback={lastFeedback}
          channel={groupChannelRef.current}
        />
      </div>
    </CustomPage>
  );
};

export default MessageSendPage;
