import { Redirect } from 'react-router-dom';

import Template from '#/components/Template';
import TutorRouter from './TutorRouter';
import ContentManagerRouter from './ContentManagerRouter';
import { useAuthContext } from '../contexts/AuthContext';

const PrivateRouter = (): JSX.Element => {
  const { isTutor } = useAuthContext();

  if (isTutor == null) {
    return <Redirect to="/" />;
  }

  return (
    <Template isTutor={isTutor}>
      {isTutor
        ? <TutorRouter />
        : <ContentManagerRouter />}
    </Template>

  );
};

export default PrivateRouter;
