import { ThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';

import RouteIndex from './routes/RouteIndex';
import AuthContextProvider, { AuthContext } from './contexts/AuthContext';
import {
  contentManagerTheme,
  tutorTheme,
} from './styles/theme';
import apolloClient from './modules/ApolloClient';
import SnackbarContextProvider from './contexts/AlertContext';
import CustomSnackbar from './components/CustomSnackbar';

const App = (): JSX.Element => (
  <SnackbarContextProvider>
    <ApolloProvider client={apolloClient}>
      <AuthContextProvider>
        <AuthContext.Consumer>
          {/* https://reactjs.org/docs/context.html#contextconsumer */}
          {({ isTutor = true }) => (
            <ThemeProvider
              theme={isTutor ? tutorTheme : contentManagerTheme}
            >
              <CssBaseline />
              <RouteIndex />
              <CustomSnackbar />
            </ThemeProvider>
          )}
        </AuthContext.Consumer>
      </AuthContextProvider>
    </ApolloProvider>
  </SnackbarContextProvider>
);

export default App;
