/* eslint-disable no-alert */
// TODO: Replace all alert to toast-like component

import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';

import dayjs from '#/modules/ExtendedDayjs';
import Firebase from '#/modules/Firebase';
import {
  FirebaseUser,
} from '#/types';
import ApiRequest from '#/modules/ApiRequest';
import useLocalStorage from '#/hooks/useLocalStorage';
import { LOCAL_STORAGE_TIMEZONE } from '#/constants';
import JSUtility from '#/util/JSUtility';
import {
  useAlertContext,
  Severity,
} from './AlertContext';

interface ContextProps {
  user: FirebaseUser | null;
  isAuthenticated: boolean;
  isUserLoaded: boolean;
  timeZone: string;
  isTutor?: boolean;
  setTimeZone?: Dispatch<SetStateAction<string>>;
  setUser?: Dispatch<SetStateAction<FirebaseUser | null>>;
  login?: (email: string, password: string) => Promise<void>;
  sendForgotPassword?: (email: string) => Promise<void>;
  logout?: () => Promise<void>;
  changePassword?: (
    currentPassword: string,
    newPassword: string
  ) => Promise<void>;
}

const initialContextProps: ContextProps = {
  user: null,
  isAuthenticated: false,
  isUserLoaded: true,
  timeZone: 'Asia/Seoul',
};

export const AuthContext = createContext<ContextProps>(initialContextProps);

const AuthContextProvider = (
  { children }: { children: React.ReactNode },
): JSX.Element => {
  const { openAlert } = useAlertContext();
  const [user, setUser] = useState(null as FirebaseUser | null);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [isTutor, setIsTutor] = useState<boolean | undefined>(undefined);
  // User defined timeZone should be fetched from database.
  const [localStorageTimeZone, setLocalStorageTimeZone] = useLocalStorage(
    LOCAL_STORAGE_TIMEZONE,
    dayjs.tz.guess(),
  );

  const login = useCallback(async (email: string, password: string) => {
    await ApiRequest.signInWithEmailAndPassword(email, password);
  }, []);

  const sendForgotPassword = useCallback(async (email: string) => {
    await ApiRequest.sendPasswordResetEmail(email);
  }, []);

  const logout = useCallback(async () => {
    await ApiRequest.signOut();
    window.location.reload();
  }, []);

  const changePassword = useCallback(async (currentPassword: string, newPassword: string) => {
    if (user == null) {
      return;
    }

    await ApiRequest.updatePassword(user, currentPassword, newPassword);
  }, [user]);

  useEffect(() => {
    const unsubscribe = Firebase.auth().onAuthStateChanged(
      async (firebaseUser) => {
        if (firebaseUser != null) {
          setUser(firebaseUser);
          if (JSUtility.isDevelopment()) {
            // eslint-disable-next-line no-console
            console.log(`User: ${firebaseUser.uid}`);
          }

          try {
            const isTutorResult = await ApiRequest.getIsTutor(firebaseUser.uid);
            setIsTutor(isTutorResult);
          } catch (err) {
            openAlert(Severity.ERROR, err);
            // When failed to check isTutor, need to logged out.
            logout();
          }
        }
        setIsUserLoaded(true);
      },
    );

    return () => unsubscribe();
  }, [isTutor, logout, openAlert]);

  return (
    <AuthContext.Provider value={{
      user,
      isAuthenticated: user !== null,
      isUserLoaded,
      isTutor,
      timeZone: localStorageTimeZone,
      setUser,
      login,
      sendForgotPassword,
      logout,
      changePassword,
      setTimeZone: setLocalStorageTimeZone,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): ContextProps => useContext(AuthContext);

export default AuthContextProvider;
