import React from 'react';
import {
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import { ROUTE_NAMES } from '#/constants';
import MessageSendPage from '#/pages/MessageSendPage';
import MessagesPage from '#/pages/MessagesPage';
import ProfilePage from '#/pages/ProfilePage';
import SendBirdContextProvider from '#/contexts/SendBirdContext';

const TutorRouter = (): JSX.Element => (
  <SendBirdContextProvider>
    <Switch>
      <Route exact path={ROUTE_NAMES.MESSAGE_ID} component={MessageSendPage} />
      <Route exact path={ROUTE_NAMES.MESSAGES} component={MessagesPage} />
      <Route exact path={ROUTE_NAMES.PROFILE} component={ProfilePage} />
      <Redirect to={ROUTE_NAMES.MESSAGES} />
    </Switch>
  </SendBirdContextProvider>
);

export default TutorRouter;
