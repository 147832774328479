import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { ChatMessageAlign } from '#/types';

const useStyles = makeStyles({
  paperLeft: {
    borderRadius: '0px 20px 20px 20px',
  },
  paperRight: {
    borderRadius: '20px 0px 20px 20px',
  },
  paper: {
    width: '70%',
    padding: '1rem',
  },
});

interface ChatPaperProps {
  children: React.ReactNode;
  align?: ChatMessageAlign;
  className?: string;
}

const ChatPaper = ({
  children, align = ChatMessageAlign.RIGHT, className,
}: ChatPaperProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper className={
      [
        classes.paper,
        className,
        align === ChatMessageAlign.LEFT
          ? classes.paperLeft
          : classes.paperRight,
      ].join(' ')
    }
    >
      {children}
    </Paper>
  );
};

export default ChatPaper;
