import {
  useMemo,
  useCallback,
  createContext,
  useContext,
  useState,
  ReactNode,
} from 'react';

export enum Severity {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

const initialAlertContext = {
  open: false,
  message: '',
  severity: Severity.INFO,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openAlert: (newSeverity: Severity, newMessage: string) => {},
  closeAlert: () => {},
};

type AlertContextProps = typeof initialAlertContext;
export const AlertContext = createContext<AlertContextProps>(initialAlertContext);

interface AlertContextProviderProps {
  children: ReactNode;
}

const AlertContextProvider = ({ children }: AlertContextProviderProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [severity, setSeverity] = useState<Severity>(Severity.INFO);

  const closeAlert = useCallback(() => {
    setOpen(false);
  }, []);

  const openAlert = useCallback((newSeverity: Severity, newMessage: string) => {
    setOpen(true);
    setSeverity(newSeverity);
    setMessage(newMessage);
  }, []);

  const alertContextValue = useMemo(() => ({
    open,
    message,
    severity,
    openAlert,
    closeAlert,
  }), [open, message, severity, openAlert, closeAlert]);

  return (
    <AlertContext.Provider value={alertContextValue}>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlertContext = (): AlertContextProps => useContext(AlertContext);

export default AlertContextProvider;
