import {
  useMemo,
  useCallback,
  useState,
  Dispatch,
  useRef,
  useEffect,
  SetStateAction,
} from 'react';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import dayjs from '#/modules/ExtendedDayjs';
import JSUtility from '#/util/JSUtility';
import MessageFeedback from './MessageFeedback';
import MessageDetailItem from './MessageDetailItem';
import {
  CORRECT_SENTENCE,
  EXPLANATION,
  FEEDBACK_EXPLANATION_MAX_LENGTH,
  REVIEW,
  TEACHING_GUIDE_URL,
} from '#/constants';
import SendBird from '#/modules/SendBird';
import { CustomMessageType } from '#/types';
import {
  useAlertContext,
  Severity,
} from '#/contexts/AlertContext';

const SEND_MESSAGE_TERM_IN_MS = 300; // SendMessage can call 5 times per 1 second.

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  orderedList: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'decimal',
    listStylePosition: 'inside',
    gap: 40,
  },
  textBox: {
    padding: '1rem',
  },
  button: {
    padding: '1rem',
  },
});

const getErrorText = {
  review: (text: string) => (text.length === 0 ? 'Please enter overall review.' : ''),
  correctSentence: (text: string) => (text.length === 0 ? '(Required) Correct student\'s sentence.' : ''),
  explanation: (text: string) => (
    text.length >= 600
      ? `Maximum ${FEEDBACK_EXPLANATION_MAX_LENGTH} characters. ${text.length}/${FEEDBACK_EXPLANATION_MAX_LENGTH}`
      : ''
  ),
};

const initialReview = {
  value: '',
  errorText: getErrorText[REVIEW](''),
};

const getInitialFeedbacks = (
  sentence: string,
) => (
  {
    [CORRECT_SENTENCE]: {
      value: sentence,
      errorText: getErrorText[CORRECT_SENTENCE](sentence),
    },
    [EXPLANATION]: {
      value: '',
      errorText: getErrorText[EXPLANATION](''),
    },
  }
);

interface QuestionProps {
  message: string;
  sentAt: Date;
}

interface StudentAnswerProps {
  message: string;
  receivedAt: Date;
}

interface LastFeedbackProps {
  didFeedbackInSameUnit: boolean;
  didFeedbackAt: Date;
}

interface MessageDetailItemsProps {
  setMessages: Dispatch<SetStateAction<SendBird.UserMessage[]>>;
  setMessageDetailItemHeight: Dispatch<number>;
  question?: QuestionProps;
  studentAnswer?: StudentAnswerProps;
  lastFeedback?: LastFeedbackProps;
  channel?: SendBird.GroupChannel;
}

const MessageDetailItems = ({
  setMessages,
  question,
  setMessageDetailItemHeight,
  studentAnswer,
  lastFeedback,
  channel,
}: MessageDetailItemsProps): JSX.Element => {
  const classes = useStyles();
  const { openAlert } = useAlertContext();

  const containerHeightRef = useRef<HTMLDivElement | null>(null);

  const isQuestionReceivedInSameUnit = useMemo(() => {
    if (question == null) return false;

    const now = new Date();
    return JSUtility.getIsSameUnitPeriod(question.sentAt, now);
  }, [question]);

  const isAnswerReceivedInSameUnit = useMemo(() => {
    if (studentAnswer == null) return false;

    const now = new Date();
    return JSUtility.getIsSameUnitPeriod(studentAnswer.receivedAt, now);
  }, [studentAnswer]);

  const sentences = useMemo(() => (
    studentAnswer == null
      ? []
      : JSUtility.separateSentences(studentAnswer.message)
  ), [studentAnswer]);

  const [review, setReivew] = useState(initialReview);
  const [feedbacks, setFeedbacks] = useState(() => sentences.map(getInitialFeedbacks));

  const onChangeInputs = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === REVIEW) {
      const reviewError = getErrorText[REVIEW](value);
      setReivew({ errorText: reviewError, value });
      return;
    }

    const [prefix, index] = name.split('-');
    const indexNumber = parseInt(index, 10);

    setFeedbacks((prevFeedbacks) => {
      const newFeedbacks = [...prevFeedbacks];
      newFeedbacks[indexNumber][prefix].value = value;
      newFeedbacks[indexNumber][prefix].errorText = getErrorText[prefix](value);
      return newFeedbacks;
    });
  }, []);

  const canSend = useMemo(() => {
    const isErrorOnReview = review.errorText.length !== 0;
    const isErrorOnFeedbacks = feedbacks.some(
      ({ correctSentence, explanation }) => (
        correctSentence.errorText.length !== 0 || explanation.errorText.length !== 0),
    );

    return (isErrorOnReview || isErrorOnFeedbacks) === false;
  }, [review, feedbacks]);

  const onClickSubmit = useCallback((e) => {
    e.preventDefault();
    if (channel == null) return;

    const params = new SendBird.UserMessageParams();
    // Send Review first
    params.message = review.value;
    params.customType = CustomMessageType.REVIEW;

    let errorOccured = false;

    channel.sendUserMessage(params, (message, error) => {
      if (error) {
        openAlert(Severity.ERROR, error.message);
        errorOccured = true;
      }
    });

    feedbacks.forEach(({ correctSentence, explanation }, index) => {
      const feedbackParams = new SendBird.UserMessageParams();
      const message = explanation.value.length === 0
        ? `(기존) ${sentences[index]}\n(수정) ${correctSentence.value}`
        : `(기존) ${sentences[index]}\n(수정) ${correctSentence.value}\n(설명) ${explanation.value})`;

      feedbackParams.message = message;
      feedbackParams.customType = CustomMessageType.FEEDBACK;

      const dmp = JSUtility.diffWordsAsDMP(sentences[index], correctSentence.value);
      const feedbackdata = {
        dmp,
        explanation: explanation.value,
      };
      feedbackParams.data = JSON.stringify({ feedback: feedbackdata });

      setTimeout(() => {
        channel.sendUserMessage(feedbackParams, (_message, error) => {
          if (!_message.isUserMessage()) {
            return;
          }

          if (error) {
            openAlert(Severity.ERROR, error.message);
            errorOccured = true;
            return;
          }

          setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            newMessages.unshift(_message as SendBird.UserMessage);
            return newMessages;
          });
        });
      }, SEND_MESSAGE_TERM_IN_MS * index);
      // For example, if there are 10 sentences to send,
      // it will request sendUserMessage 300, 600, 900, 1200, ... 3000 ms.
    });

    if (!errorOccured) {
      openAlert(Severity.SUCCESS, 'Message has been sent successfully!');
    }
  }, [
    openAlert,
    setMessages,
    channel,
    review.value,
    sentences,
    feedbacks,
  ]);

  useEffect(() => {
    if (containerHeightRef.current == null) return;

    setMessageDetailItemHeight(containerHeightRef.current.offsetHeight);
  }, [setMessageDetailItemHeight]);

  return (
    <div className={classes.root} ref={containerHeightRef}>
      <ol className={classes.orderedList}>
        <MessageDetailItem
          title="Today's question."
          {...((question == null || !isQuestionReceivedInSameUnit)
            ? { titleOption: '(Not sent)' }
            : {
              titleOption: `(${dayjs(question.sentAt).format('[Sent on] YYYY.MM.DD [at] HH:mm')})`,
              body: (
                <Paper className={classes.textBox}>
                  <Typography>{question.message}</Typography>
                </Paper>
              ),
            })}
        />
        {question == null || !isQuestionReceivedInSameUnit
          ? null
          : (studentAnswer == null || !isAnswerReceivedInSameUnit)
            ? (
              <MessageDetailItem
                title="You haven’t received an answer for today."
              />
            )
            : (
              <>
                <MessageDetailItem
                  title="(Required) Give an overall review for the student’s answer."
                  titleOption={`(${dayjs(studentAnswer.receivedAt).format('[Received on] YYYY.MM.DD [at] HH:mm')})`}
                  body={(
                    <>
                      <Paper className={classes.textBox}>
                        <Typography>
                          {studentAnswer.message}
                        </Typography>
                      </Paper>
                      <TextField
                        name="review"
                        value={review.value}
                        onChange={onChangeInputs}
                        variant="outlined"
                        placeholder="Write a overall review."
                        fullWidth
                        multiline
                        required
                        error={review.errorText.length !== 0}
                        helperText={review.errorText}
                      />
                      <Typography variant="caption">{'Mention student\'s name to compliment and encourage while giving an overall review.'}</Typography>
                    </>
                  )}
                />
                <MessageDetailItem
                  title="Correct the error(s) in the student's text message."
                  titleOption={(
                    <Box px={1} display="inline">
                      <Link
                        target="_blank"
                        href={TEACHING_GUIDE_URL}
                      >
                        Teaching Guide
                      </Link>
                    </Box>
                  )}
                  body={(
                    <Box py={4}>
                      <Grid container spacing={4}>
                        {sentences.map((sentence, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Grid item xs={12} key={`${sentence}-${i}`}>
                            <MessageFeedback
                              original={sentence}
                              correctSentence={feedbacks[i].correctSentence.value}
                              correctSentenceErrorText={feedbacks[i].correctSentence.errorText}
                              explanation={feedbacks[i].explanation.value}
                              explanationErrorText={feedbacks[i].explanation.errorText}
                              onChange={onChangeInputs}
                              index={i}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                />
              </>
            )}
      </ol>
      {(isQuestionReceivedInSameUnit && isAnswerReceivedInSameUnit)
        && (
          <Button
            className={classes.button}
            onClick={onClickSubmit}
            disabled={lastFeedback?.didFeedbackInSameUnit || !canSend}
            fullWidth
            variant="contained"
            color="primary"
          >
            <Typography variant="button">
              SEND
              {
                lastFeedback?.didFeedbackInSameUnit === true
                && `\n${dayjs(lastFeedback.didFeedbackAt).format('(YYYY.MM.DD. HH:mm:ss Sent)')}`
              }
            </Typography>
          </Button>
        )}
    </div>
  );
};

export default MessageDetailItems;
