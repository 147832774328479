import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '1rem',
    textTransform: 'capitalize',
  },
  primitiveRightComponent: {
    fontSize: '1rem',
  },
  formData: {
    width: '100%',
  },
});

interface ContentItemProps {
  label: string;
  rightComponent: JSX.Element | string | number;
}

const ContentItem = ({
  label, rightComponent,
}: ContentItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.item} item xs={2}>
        <Typography className={classes.label} variant="subtitle1">
          {label}
        </Typography>
      </Grid>
      <Grid className={classes.item} item xs={10}>
        {typeof rightComponent === 'string' || typeof rightComponent === 'number'
          ? (
            <Typography className={classes.primitiveRightComponent} variant="subtitle1">
              {rightComponent}
            </Typography>
          )
          : rightComponent}
      </Grid>
    </>
  );
};

export default ContentItem;
