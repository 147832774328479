import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  UIEvent,
  useState,
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import SendBird from '#/modules/SendBird';
import dayjs from '#/modules/ExtendedDayjs';
import ChatMessage from '../message/ChatMessage';
import ChatDateDivider from '../message/ChatDateDivider';
import {
  useAlertContext,
  Severity,
} from '#/contexts/AlertContext';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  helperText: {
    padding: '1rem 0',
  },
  header: {
    padding: '1rem',
  },
  headerText: {
    fontWeight: 'bold',
  },
  messagesContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    background: '#F1F3F5',
    padding: '1rem',
    overflow: 'scroll',
    gap: 10,
  },
});

const MESSAGE_CONTAINER_DEFAULT_HEIGHT = 600;

interface MessagesLogProps {
  messages: SendBird.UserMessage[];
  messageDetailItemHeight: number;
  loadMore: () => Promise<void>
}

const MessagesLog = ({
  messages,
  messageDetailItemHeight,
  loadMore,
}: MessagesLogProps): JSX.Element => {
  const classes = useStyles();

  const previousMessagesRef = useRef<SendBird.UserMessage[] | null>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { openAlert } = useAlertContext();

  const scrollToBottomInMessagesContainer = useCallback(() => {
    if (messageContainerRef.current == null) return;

    messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
  }, []);

  useEffect(() => {
    const previousMessages = previousMessagesRef.current;
    previousMessagesRef.current = messages;
    // First render
    if (previousMessages == null) {
      scrollToBottomInMessagesContainer();
      return;
    }

    const latestMessage = messages[0];
    const previousLatestMessage = previousMessages[0];
    const isUpdatedNewMessage = latestMessage.createdAt > previousLatestMessage.createdAt;

    if (isUpdatedNewMessage) {
      scrollToBottomInMessagesContainer();
    }
  }, [scrollToBottomInMessagesContainer, messages]);

  const messageContainerHeight = useMemo(() => {
    if (messageContainerRef.current == null) return MESSAGE_CONTAINER_DEFAULT_HEIGHT;

    return (
      messageDetailItemHeight - messageContainerRef.current.offsetTop
    );
  }, [messageDetailItemHeight]);

  const handleScroll = useCallback(async (e: UIEvent<HTMLDivElement>) => {
    if (isLoadingMore) return;

    const { scrollTop, scrollHeight } = e.currentTarget;
    const thresholdForLoadMore = Math.floor(scrollHeight / 4);
    if (scrollTop > thresholdForLoadMore) return;

    setIsLoadingMore(true);
    try {
      await loadMore();
    } catch (err) {
      openAlert(Severity.ERROR, err.message);
    } finally {
      setIsLoadingMore(false);
    }
  }, [loadMore, isLoadingMore, openAlert]);

  const renderMessages = useCallback(() => {
    const reversedMessages = messages.slice().reverse();
    let lastDateString = '';

    const messagesWithSeperator = reversedMessages.map((message) => {
      const dateString = dayjs(message.createdAt).format('MMM D, YYYY');
      const isRenderDateSeperator = lastDateString !== dateString;

      if (isRenderDateSeperator) {
        lastDateString = dateString;
      }

      return (
        <React.Fragment key={message.messageId}>
          {isRenderDateSeperator && <ChatDateDivider>{dateString}</ChatDateDivider>}
          <ChatMessage message={message} />
        </React.Fragment>
      );
    });

    return messagesWithSeperator;
  }, [messages]);

  return (
    <div
      className={classes.root}
    >
      <Typography variant="body2" gutterBottom>Message sent from the system: It will be displayed on student’s screen as a message that was sent from the tutor.</Typography>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h6" className={classes.headerText}>
            Message Log
          </Typography>
        </div>
        <div
          style={{ height: messageContainerHeight }}
          className={classes.messagesContainer}
          onScroll={handleScroll}
          ref={messageContainerRef}
        >
          {renderMessages()}
        </div>
      </Paper>
    </div>
  );
};

export default MessagesLog;
