import {
  useState,
  useCallback,
  useMemo,
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ProfileTab from '#/components/profile/ProfileTab';
import {
  ProfileTabs,
} from '#/types';
import ProfileTimeZone from '#/components/profile/ProfileTimeZone';
import ProfilePassword from '#/components/profile/ProfilePassword';
import CustomPage from '#/components/CustomPage';

const useStyles = makeStyles({
  item: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const ProfilePage = (): JSX.Element => {
  const classes = useStyles();
  const [tab, setTab] = useState<ProfileTabs>(ProfileTabs.TIME_ZONE);

  const onChangeTab = useCallback((_: unknown, newTab: ProfileTabs) => {
    setTab(newTab);
  }, [setTab]);

  const ProfileItem = useMemo(() => {
    switch (tab) {
      case ProfileTabs.TIME_ZONE:
        return <ProfileTimeZone />;
      case ProfileTabs.PASSWORD:
        return <ProfilePassword />;
      default:
        return null;
    }
  }, [tab]);

  return (
    <CustomPage>
      <ProfileTab defaultTab={tab} onChangeTab={onChangeTab} />
      <div className={classes.item}>
        {ProfileItem}
      </div>
    </CustomPage>
  );
};

export default ProfilePage;
