import TextField from '@material-ui/core/TextField';

interface ContentInputProps {
  name: string;
  value: string;
}

const ContentInput = ({
  name,
  value,
}: ContentInputProps): JSX.Element => (
  <TextField
    name={name}
    fullWidth
    required
    variant="outlined"
    placeholder="Type question"
    value={value}
  />
);

export default ContentInput;
