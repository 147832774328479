// Reference from https://material-ui.com/components/tables/#EnhancedTable.tsx
import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';

import usePrevious from '#/hooks/usePrevious';

import {
  TableData,
  Order,
  HeadCell,
} from '#/types';

const useStyles = makeStyles({
  root: {
  },
  table: {
    tableLayout: 'auto',
  },
  headText: {
    fontWeight: 'bold',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableSortLabel: {
    position: 'relative',
    '& .MuiTableSortLabel-icon': {
      position: 'absolute',
      right: -25,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 0',
  },
});

function descendingComparator<Data extends TableData>(a: Data, b: Data, orderBy: keyof Data) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Data extends TableData>(
  order: Order,
  orderBy: keyof Data,
): (a: Data, b: Data) => number {
  return (
    order === Order.DESC
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  );
}

function stableSort<Data extends TableData>(
  array: Data[], comparator: (a: Data, b: Data) => number,
) {
  const stabilizedThis: [Data, number][] = array.map((el, index) => ([el, index]));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps<Data> {
  head: HeadCell<Data>[];
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: keyof Data;
}

function EnhancedTableHead<Data extends TableData>({
  head, classes, order, orderBy, onRequestSort,
}: EnhancedTableProps<Data>) {
  const createSortHandler = useCallback(
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    }, [onRequestSort],
  );

  return (
    <TableHead>
      <TableRow>
        {head.map((headCell) => (
          <TableCell
            key={`${headCell.id}`}
            sortDirection={orderBy === headCell.id ? order : false}
            align={headCell.align ?? 'center'}
          >
            <TableSortLabel
              className={classes.tableSortLabel}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : Order.ASC}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography className={classes.headText} variant="body1">
                {headCell.label}
              </Typography>
              {orderBy === headCell.id && (
                <span className={classes.visuallyHidden}>
                  {order === Order.DESC ? 'sorted descending' : 'sorted ascending'}
                </span>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface CustomTableProps<Data> {
  head: HeadCell<Data>[];
  data: Data[];
  defaultOrderBy: keyof Data;
  renderItem: (row: Data) => JSX.Element;
  defaultOrder?: Order;
  BottomButton?: JSX.Element;
  rowsPerPageOptions?: number[];
}

const CustomTable = <Data extends TableData>({
  head,
  data,
  defaultOrderBy,
  renderItem,
  defaultOrder = Order.ASC,
  BottomButton,
  rowsPerPageOptions = [10],
}: CustomTableProps<Data>): JSX.Element => {
  const classes = useStyles();

  const previousData = usePrevious(data);
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = useState<keyof Data>(defaultOrderBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof Data) => {
      const isAsc = orderBy === property && order === Order.ASC;
      setOrder(isAsc ? Order.DESC : Order.ASC);
      setOrderBy(property);
    }, [order, orderBy],
  );

  const onChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const sortedData = useMemo(
    () => stableSort(data, getComparator(order, orderBy)),
    [data, order, orderBy],
  );

  const paginationCursorStart = useMemo(() => page * rowsPerPage, [page, rowsPerPage]);
  const paginationCursorEnd = useMemo(
    () => paginationCursorStart + rowsPerPage,
    [paginationCursorStart, rowsPerPage],
  );

  const emptyRows = useMemo(
    () => rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage),
    [page, rowsPerPage, data],
  );

  useEffect(() => {
    if (previousData !== data) {
      setPage(0);
    }
  }, [previousData, data]);

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            head={head}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {sortedData
              .slice(paginationCursorStart, paginationCursorEnd)
              .map(renderItem)}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={head.length}>
                  {data.length === 0 && <Typography align="center">There is no data.</Typography>}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <footer className={classes.footer}>
        <div>
          {BottomButton}
        </div>
        <div>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </div>
      </footer>
    </div>
  );
};

export default CustomTable;
