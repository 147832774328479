import ROUTE_NAMES from './routes';

export const KEYBOARD_EVENT_CODE_ENTER = 'Enter';

export const CORRECT_SENTENCE = 'correctSentence';
export const EXPLANATION = 'explanation';
export const REVIEW = 'review';

export const FEEDBACK_EXPLANATION_MAX_LENGTH = 600;
export const LOCAL_STORAGE_TIMEZONE = 'timezone';

export const UTC_TIME_CONFIG = {
  unitStartHour: 20,
  feedbackStartHour: 15,
};
export const TEACHING_GUIDE_URL = 'https://www.notion.so/hayanmind/Tutor-s-feedback-guide-0868355c27dd4c5c9d23fe62e2985629';

export const FIREBASE_AUTH_ERROR_CODE = {
  WRONG_PASSWORD: 'auth/wrong-password',
  USER_NOT_FOUND: 'auth/user-not-found',
};

export { ROUTE_NAMES };
