import React, {
  useCallback,
  useState,
  KeyboardEvent,
} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import Loading from '#/components/Loading';
import { useAuthContext } from '#/contexts/AuthContext';
import Colors from '#/styles/colors';
import {
  KEYBOARD_EVENT_CODE_ENTER,
  FIREBASE_AUTH_ERROR_CODE,
} from '#/constants';
import { FirebaseError } from '#/types';
import CustomPage from '#/components/CustomPage';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: Colors.redkiwiRed,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  loginErrorText: {
    marginTop: '0.5rem',
  },
}));

const LoginPage = (): JSX.Element => {
  const classes = useStyles();
  const [email, setEmail] = useState({
    value: '',
    hasError: false,
  });
  const [password, setPassword] = useState({
    value: '',
    hasError: false,
  });

  const { login } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [
    loginError,
    setLoginError,
  ] = useState<FirebaseError | Error | null>();

  const checkHasError = useCallback((name: 'email' | 'password', value: string) => {
    if (name === 'email') {
      return value.length === 0;
    }

    if (name === 'password') {
      return value.length === 0;
    }

    return false;
  }, []);

  const onChangeInput = useCallback((e) => {
    const { value, name } = e.target;

    if (name === 'email') {
      const isEmptyField = checkHasError('email', value);
      setEmail({ value, hasError: isEmptyField });
    }

    if (name === 'password') {
      const isEmptyField = checkHasError('password', value);
      setPassword({ value, hasError: isEmptyField });
    }
  }, [checkHasError]);

  const onClickLogin = useCallback(async () => {
    if (login == null) {
      return;
    }

    const emailError = checkHasError('email', email.value);
    const passwordError = checkHasError('email', email.value);

    if (emailError || passwordError) {
      setEmail((prevEmail) => ({ value: prevEmail.value, hasError: emailError }));
      setPassword((prevPassword) => ({ value: prevPassword.value, hasError: passwordError }));
      return;
    }

    setIsLoading(true);
    try {
      await login(email.value, password.value);
    } catch (err) {
      const isInvalidIdPassword = (
        err.code === FIREBASE_AUTH_ERROR_CODE.USER_NOT_FOUND
        || err.code === FIREBASE_AUTH_ERROR_CODE.WRONG_PASSWORD
      );

      if (isInvalidIdPassword) {
        setLoginError(new Error('Please check your email and password'));
        return;
      }

      setLoginError(err);
    } finally {
      setIsLoading(false);
    }
  }, [login, email, password, checkHasError]);

  const onKeyDownEnter = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === KEYBOARD_EVENT_CODE_ENTER) {
      e.preventDefault();
      onClickLogin();
    }
  }, [onClickLogin]);

  if (isLoading) return <Loading />;

  return (
    <CustomPage className={classes.container} hideBreadcrumbs>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src="/favicon.ico" alt="logo" />
        </Avatar>
        <Typography component="h1" variant="h5">
          RedKiwi Messenger Website
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            name="email"
            autoComplete="email"
            id="email"
            error={email.hasError}
            helperText={email.hasError && 'Email is required'}
            autoFocus
            onChange={onChangeInput}
            onKeyDown={onKeyDownEnter}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            name="password"
            type="password"
            id="password"
            error={password.hasError}
            helperText={password.hasError && 'Password is required'}
            autoComplete="current-password"
            onChange={onChangeInput}
            onKeyDown={onKeyDownEnter}
          />
          <Typography
            className={classes.loginErrorText}
            color="error"
          >
            {loginError?.message ?? ''}
          </Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onClickLogin}
          >
            Login
          </Button>
          <Grid container justify="flex-end">
            <Link component={RouterLink} to="/forgot-password">
              Forgot password?
            </Link>
          </Grid>
        </form>
      </div>
    </CustomPage>
  );
};

export default LoginPage;
