import {
  KeyboardEvent,
  useCallback,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useAuthContext } from '#/contexts/AuthContext';
import Colors from '#/styles/colors';
import {
  KEYBOARD_EVENT_CODE_ENTER,
  FIREBASE_AUTH_ERROR_CODE,
} from '#/constants/index';
import { FirebaseError } from '#/types/index';
import CustomPage from '#/components/CustomPage';
import Loading from '#/components/Loading';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  paper: {
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: Colors.redkiwiRed,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const defaultEmailValue = {
  value: '',
  hasError: false,
};

const ForgotPasswordPage = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { sendForgotPassword } = useAuthContext();
  const [email, setEmail] = useState(defaultEmailValue);

  const [isLoading, setIsLoading] = useState(false);
  const [
    forgotPasswordError,
    setforgotPasswordError,
  ] = useState<FirebaseError | Error | null>(null);

  const checkHasError = useCallback((value: string) => value.length === 0, []);

  const onChangeEmail = useCallback((e) => {
    const { value } = e.target;
    const hasError = checkHasError(value);
    setEmail({ value, hasError });
    setforgotPasswordError(null);
  }, [checkHasError]);

  const onClickCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onClickSubmit = useCallback(async () => {
    const hasError = checkHasError(email.value);

    if (hasError) {
      setEmail((prevEmail) => ({ value: prevEmail.value, hasError }));
      return;
    }

    if (sendForgotPassword != null) {
      setIsLoading(true);
      try {
        await sendForgotPassword(email.value);
      } catch (err) {
        const isInvalidIdPassword = (
          err.code === FIREBASE_AUTH_ERROR_CODE.USER_NOT_FOUND
          || err.code === FIREBASE_AUTH_ERROR_CODE.WRONG_PASSWORD
        );

        if (isInvalidIdPassword) {
          setforgotPasswordError(new Error('Please check your email and password'));
          return;
        }

        setforgotPasswordError(err);
      }
      setEmail(defaultEmailValue);
      setIsLoading(false);
    }
  }, [checkHasError, email, sendForgotPassword]);

  const onKeyDownEnter = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === KEYBOARD_EVENT_CODE_ENTER) {
      e.preventDefault();
      onClickSubmit();
    }
  }, [onClickSubmit]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CustomPage className={classes.container} hideBreadcrumbs>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src="/favicon.ico" alt="logo" />
        </Avatar>
        <Typography>
          Forgot password?
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Email Address"
            id="email"
            name="email"
            error={email.hasError}
            helperText={email.hasError && 'Email is required'}
            autoFocus
            onChange={onChangeEmail}
            onKeyDown={onKeyDownEnter}
          />
          <Typography color="error">
            {forgotPasswordError?.message ?? ''}
          </Typography>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={6}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="default"
                className={classes.button}
                onClick={onClickCancel}
              >
                <Typography variant="button">
                  Cancel
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onClickSubmit}
              >
                <Typography variant="button">
                  Submit
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </CustomPage>
  );
};

export default ForgotPasswordPage;
