import { makeStyles } from '@material-ui/core/styles';

import Colors from '#/styles/colors';

const useStyles = makeStyles({
  separator: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',

    '&::before, &::after': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${Colors.darkGray}`,
    },

    '&:not(:empty)::before': {
      marginRight: '0.25rem',
    },

    '&:not(:empty)::after': {
      marginLeft: '0.25rem',
    },
  },
});

const ChatDateDivider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.separator}>
      {children}
    </div>
  );
};

export default ChatDateDivider;
