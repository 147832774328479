import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { useCallback } from 'react';
import { useAlertContext } from '#/contexts/AlertContext';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const snackbarAnchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const CustomSnackbar = (): JSX.Element => {
  const {
    open, message, severity, closeAlert,
  } = useAlertContext();

  const handleClose = useCallback(
    (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      closeAlert();
    }, [closeAlert]);

  return (
    <div>
      <Snackbar
        anchorOrigin={snackbarAnchorOrigin}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomSnackbar;
