import Typography from '@material-ui/core/Typography';

import {
  ChatMessageProps,
} from '#/types/index';
import ChatPaper from './ChatPaper';

const BasicChatMessage = ({ message }: ChatMessageProps): JSX.Element => (
  <ChatPaper>
    <Typography>
      {message.message}
    </Typography>
  </ChatPaper>
);

export default BasicChatMessage;
