import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import Colors from '#/styles/colors';

const NOTOSANS = ['Noto Sans KR', 'Noto Sans CJK JP', 'sans-serif'];

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.tutorColor,
    },
    secondary: {
      main: Colors.contentManagerColor,
    },
  },
  typography: {
    fontFamily: NOTOSANS.join(','),
    button: {
      textTransform: 'none',
      fontFamily: NOTOSANS.join(','),
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        whiteSpace: 'pre-wrap',
      },
    },
  },
});

export const tutorTheme = createMuiTheme({
  ...theme,
  palette: {
    primary: {
      main: Colors.tutorColor,
    },
    secondary: {
      main: Colors.contentManagerColor,
    },
  },
});

export const contentManagerTheme = createMuiTheme({
  ...theme,
  palette: {
    primary: {
      main: Colors.contentManagerColor,
    },
  },
});
