/* eslint-disable max-len */
import {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import dayjs from '#/modules/ExtendedDayjs';
import ContentsSearch from '#/components/contents/ContentsSearch';
import ContentsTab from '#/components/contents/ContentsTab';
import ContentsTable from '#/components/contents/ContentsTable';
import CustomPage from '#/components/CustomPage';
import {
  ContentTableData,
  ContentTabs,
} from '#/types';
import ApiRequest from '#/modules/ApiRequest';
import Loading from '#/components/Loading';
import JSUtility from '#/util/JSUtility';
import { ROUTE_NAMES } from '#/constants';
import {
  useAlertContext,
  Severity,
} from '#/contexts/AlertContext';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  button: {
    padding: '1rem 2rem',
  },
  buttonText: {
    fontSize: '1rem',
  },
});

const ContentsPage = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { openAlert } = useAlertContext();

  const [searchKeyword, setSearchKeyword] = useState('');
  const [tab, setTabOption] = useState<ContentTabs>(ContentTabs.ALL);
  const [contentsData, setContentsData] = useState<ContentTableData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  }, []);

  const onChangeTab = useCallback((_, newOption) => {
    setTabOption(newOption);
  }, []);

  const tabInfo = useMemo(() => {
    const all = contentsData.length;
    const published = contentsData.filter((data: ContentTableData) => data.published).length;
    const drafts = all - published;

    return {
      [ContentTabs.ALL]: all,
      [ContentTabs.PUBLISHED]: published,
      [ContentTabs.DRAFTS]: drafts,
    };
  }, [contentsData]);

  const fetchContents = useCallback(async () => {
    const [contents, configs] = await Promise.all([
      ApiRequest.getContents(),
      ApiRequest.getMessengerContentConfigs(),
    ]);

    if (contents == null) {
      return;
    }

    const topicMap = JSUtility.convertOptionsArrayToOptionMap(configs.topics);
    const levelMap = JSUtility.convertOptionsArrayToOptionMap(configs.levels);

    const newContentsData: ContentTableData[] = contents.map((data) => ({
      id: data.id,
      createdAt: dayjs(data.createdAt),
      topic: topicMap.get(data.topicId) ?? 'Unknown',
      level: levelMap.get(data.levelId) ?? 'Unknown',
      // questionNumber can be null
      questionNumber: data.contentNumber ?? -1,
      question: data.question,
      sent: data.sentCount,
      answered: data.answeredCount,
      completed: JSUtility.getCompleteRate(data.sentCount, data.answeredCount),
      published: data.published,
    }));

    setContentsData(newContentsData);
  }, []);

  useEffect(() => {
    const getContents = async () => {
      setIsLoading(true);
      try {
        await fetchContents();
      } catch (err) {
        openAlert(Severity.ERROR, err);
      } finally {
        setIsLoading(false);
      }
    };

    getContents();
  }, [openAlert, fetchContents]);

  const onClickAddContent = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    history.push(ROUTE_NAMES.CONTENT_ADD);
  }, [history]);

  if (isLoading) return <Loading />;

  return (
    <CustomPage
      className={classes.container}
      pathname="Contents"
      breadcrumbsComponent={(
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={onClickAddContent}
        >
          <Typography className={classes.buttonText} variant="button">Add Content</Typography>
        </Button>
      )}
    >
      <ContentsTab defaultTab={tab} onChangeTab={onChangeTab} tabInfo={tabInfo} />
      <ContentsSearch searchKeyword={searchKeyword} onChangeSearch={onChangeSearch} />
      <ContentsTable data={contentsData} searchKeyword={searchKeyword} tab={tab} />
    </CustomPage>
  );
};

export default ContentsPage;
