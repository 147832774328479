/* eslint-disable no-alert */
// TODO: Replace all alert to toast-like component

import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import SendBird from '#/modules/SendBird';
import { useAuthContext } from './AuthContext';
import ApiRequest from '../modules/ApiRequest';

interface ContextProps {
  sbUser: SendBird.User | null;
}

const initialContextProps: ContextProps = {
  sbUser: null,
};

export const SendBirdContext = createContext<ContextProps>(initialContextProps);

const SendBirdContextProvider = (
  { children }: { children: React.ReactNode },
): JSX.Element => {
  const [sbUser, setSbUser] = useState<SendBird.User | null>(null);
  const { user } = useAuthContext();

  useEffect(() => {
    const connectSendBird = async () => {
      if (user == null) {
        return;
      }
      const sendBirdToken = await ApiRequest.getSendBirdToken(user.uid);
      const sendBirdUser = await SendBird.connect(user.uid, sendBirdToken);
      setSbUser(sendBirdUser);
    };

    connectSendBird();
  }, [user]);

  return (
    <SendBirdContext.Provider value={{
      sbUser,
    }}
    >
      {children}
    </SendBirdContext.Provider>
  );
};

export const useSendBirdContext = (): ContextProps => useContext(SendBirdContext);

export default SendBirdContextProvider;
