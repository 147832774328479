import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import dayjs from '#/modules/ExtendedDayjs';
import ContentItem from './ContentItem';
import ContentSelect from './ContentSelect';
import ContentInput from './ContentInput';
import ContentRadioGroup from './ContentRadioGroup';

import {
  OptionsToRender,
  ContentAddData,
} from '#/types';

const useStyles = makeStyles({
  root: {
    margin: '1rem 0',
  },
});

interface ContentAddFormProps {
  data: ContentAddData;
  onChangeData: (e) => void;
  optionsToRender: OptionsToRender;
}

const now = dayjs().format('YYYY-MM-DD HH:mm:ss');

const ContentAddForm = ({
  data,
  onChangeData,
  optionsToRender,
}: ContentAddFormProps): JSX.Element => {
  const classes = useStyles();

  return (
    <form onChange={onChangeData}>
      <Grid className={classes.root} container spacing={2}>
        <ContentItem
          label="Created"
          rightComponent={now}
        />
        <ContentItem
          label="Status"
          rightComponent={(
            <ContentRadioGroup
              name="published"
              options={optionsToRender.status}
              value={data.published}
            />
          )}
        />
        <ContentItem
          label="Topic"
          rightComponent={(
            <ContentSelect
              name="topic"
              options={optionsToRender.topic}
              value={data.topic}
            />
          )}
        />
        <ContentItem
          label="Level"
          rightComponent={(
            <ContentSelect
              name="level"
              options={optionsToRender.level}
              value={data.level}
            />
          )}
        />
        <ContentItem
          label="Question"
          rightComponent={(
            <ContentInput
              name="question"
              value={data.question}
            />
          )}
        />
      </Grid>
    </form>
  );
};

export default ContentAddForm;
