import React from 'react';
import {
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import { ROUTE_NAMES } from '#/constants';
import ContentAddPage from '#/pages/ContentAddPage';
import ContentEditPage from '#/pages/ContentEditPage';
import ContentPage from '#/pages/ContentsPage';
import ProfilePage from '#/pages/ProfilePage';

const ContentManagerRouter = (): JSX.Element => (
  <Switch>
    <Route exact path={ROUTE_NAMES.CONTENT_ADD} component={ContentAddPage} />
    <Route exact path={ROUTE_NAMES.CONTENT} component={ContentEditPage} />
    <Route exact path={ROUTE_NAMES.CONTENTS} component={ContentPage} />
    <Route exact path={ROUTE_NAMES.PROFILE} component={ProfilePage} />
    <Redirect to={ROUTE_NAMES.CONTENTS} />
  </Switch>
);

export default ContentManagerRouter;
