import {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import {
  ContentTableData,
  ContentTabs,
  HeadCell,
  Order,
} from '#/types';
import Utility from '#/util/JSUtility';
import CustomTable from '#/components/CustomTable';
import Colors from '#/styles/colors';
import {
  ROUTE_NAMES,
} from '#/constants';

interface ContentsTableProps {
  data: ContentTableData[];
  searchKeyword: string;
  tab: ContentTabs;
}

const headCells: HeadCell<ContentTableData>[] = [
  { id: 'createdAt', label: 'Created', align: 'left' },
  { id: 'topic', label: 'Topic' },
  { id: 'level', label: 'Level' },
  { id: 'questionNumber', label: 'Q#' },
  { id: 'question', label: 'Question', align: 'left' },
  { id: 'sent', label: 'Sent' },
  { id: 'answered', label: 'Answered' },
  { id: 'completed', label: 'Complete Rate' },
  { id: 'published', label: 'Published' },
];

const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
  },
  questionCell: {
    position: 'relative',
  },
  question: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: 16,
  },
  redText: {
    color: Colors.redkiwiRed,
  },
});

const getObjValuesAsString = (data: ContentTableData) => ({
  createdAt: data.createdAt.format(),
  topic: data.topic,
  level: data.level,
  questionNumber: data.questionNumber.toString(),
  question: data.question,
  sent: data.sent.toString(),
  answered: data.answered.toString(),
  completed: data.completed.toString(),
  published: data.published ? 'Y' : 'N',
});

const ContentsTable = ({ data, searchKeyword, tab }: ContentsTableProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const [filteredData, setFilteredData] = useState(data);

  const filterBySearch = useCallback((rowObj: ContentTableData) => {
    if (searchKeyword.length === 0) {
      return true;
    }

    const normalizedSearchKeyword = Utility.normalizeSearch(searchKeyword);
    const objAsStringifyValues = getObjValuesAsString(rowObj);

    const isIncludeSearchString: boolean = (
      Object.values(objAsStringifyValues)
        .filter(
          (stringifiedValue) => (
            Utility.normalizeSearch(stringifiedValue).includes(normalizedSearchKeyword)
          ),
        ).length > 0
    );

    return isIncludeSearchString;
  }, [searchKeyword]);

  const filterByTab = useCallback((rowObj: ContentTableData) => {
    switch (tab) {
      case ContentTabs.PUBLISHED:
        return rowObj.published;
      case ContentTabs.DRAFTS:
        return !rowObj.published;
      case ContentTabs.ALL:
      default:
        return true;
    }
  }, [tab]);

  const onClickRow = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    const dataId = e.currentTarget.getAttribute('data-id');
    history.push(`${ROUTE_NAMES.CONTENTS}/${dataId}`);
  }, [history]);

  const renderItem = useCallback((row: ContentTableData) => (
    <TableRow
      className={classes.row}
      key={row.id}
      hover
      data-id={row.id}
      onClick={onClickRow}
      tabIndex={-1}
    >
      <TableCell align="left" width="180px">
        <Typography variant="body2">
          {row.createdAt.format('YYYY-MM-DD HH:mm:ss')}
        </Typography>
      </TableCell>
      <TableCell align="center" width="120px">
        <Typography variant="body2">
          {row.topic}
        </Typography>
      </TableCell>
      <TableCell align="center" width="120px">
        <Typography variant="body2">
          {row.level}
        </Typography>
      </TableCell>
      <TableCell align="center" width="50px">
        <Typography variant="body2">
          {row.questionNumber}
        </Typography>
      </TableCell>
      <TableCell className={classes.questionCell} align="left">
        <Typography className={classes.question} variant="body2" noWrap>
          {row.question}
        </Typography>
      </TableCell>
      <TableCell align="center" width="50px">
        <Typography variant="body2">
          {row.sent}
        </Typography>
      </TableCell>
      <TableCell align="center" width="50px">
        <Typography variant="body2">
          {row.answered}
        </Typography>
      </TableCell>
      <TableCell align="center" width="120px">
        <Typography variant="body2">
          {`${row.completed}%`}
        </Typography>
      </TableCell>
      <TableCell align="center" width="50px">
        <Typography variant="body2" className={!row.published ? classes.redText : ''}>
          {row.published ? 'Y' : 'N'}
        </Typography>
      </TableCell>
    </TableRow>
  ), [classes, onClickRow]);

  useEffect(() => {
    setFilteredData(
      data
        .filter(filterByTab)
        .filter(filterBySearch),
    );
  }, [data, filterBySearch, filterByTab]);

  return (
    <CustomTable
      head={headCells}
      data={filteredData}
      defaultOrderBy="createdAt"
      defaultOrder={Order.DESC}
      renderItem={renderItem}
      rowsPerPageOptions={[10, 25, 50]}
    />
  );
};

export default ContentsTable;
