import {
  ApolloClient,
  InMemoryCache,
  gql,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import Firebase from './Firebase';
import Utility from '#/util/JSUtility';

const FIREBASE_APP_ID = Utility.isDevelopment()
  ? 'mimiking-dev'
  : 'mimiking-8d91c';

const baseEndPoint = `https://us-central1-${FIREBASE_APP_ID}.cloudfunctions.net/graphql`; // graphql_jack

const httpLink = createHttpLink({
  uri: baseEndPoint,
});

const authLink = setContext(async (_, { headers }) => {
  // https://firebase.google.com/docs/auth/admin/verify-id-tokens?hl=ko#retrieve_id_tokens_on_clients
  const { currentUser } = Firebase.auth();
  const token = currentUser != null ? await currentUser.getIdToken() : null;

  return {
    headers: {
      ...headers,
      authorization: token != null ? `Bearer ${token}` : '',
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default apolloClient;

export const Queries = (() => ({
  GET_ME: gql`
    {
      me {
        id
      }
    }
  `,
  GET_CONTENTS: gql`
    {
      messengerContents {
        records {
          id
          contentNumber
          topicId
          levelId
          question
          published
          sentCount
          answeredCount
          createdAt
          updatedAt
        }
      }
    }
  `,
  GET_CONTENT: gql`
    query GetContent($input: MessengerContentInput!) {
      messengerContent(input: $input) {
        id
        contentNumber
        topicId
        levelId
        question
        published
        sentCount
        answeredCount
        createdAt
        updatedAt
      }
    }
  `,
  CREATE_CONTENT: gql`
    mutation CreateContent($input: CreateMessengerContentInput!) {
      createMessengerContent(input: $input) {
        content {
          id
        }
      }
    }
  `,
  UPDATE_CONTENT: gql`
    mutation UpdateContent($input: UpdateMessengerContentInput!) {
      updateMessengerContent(input: $input) {
        content {
          id
        }
      }
    }
  `,
  DELETE_CONTENT: gql`
    mutation DeleteContent($input: DeleteMessengerContentInput!) {
      deleteMessengerContent(input: $input)
    }
  `,
}))();
