import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  ChatMessageProps,
} from '#/types/index';
import ChatPaper from './ChatPaper';

const useStyles = makeStyles({
  indicatorText: {
    fontWeight: 'bold',
  },
});

const QuestionChatMessage = ({ message }: ChatMessageProps): JSX.Element => {
  const classes = useStyles();
  return (
    <ChatPaper>
      <Typography className={classes.indicatorText}>
        {'[Today\'s Question]'}
      </Typography>
      <Typography>
        {message.message}
      </Typography>
    </ChatPaper>
  );
};

export default QuestionChatMessage;
