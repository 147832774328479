import Firebase, { EmailAuthProvider } from './Firebase';
import {
  FirebaseUser,
  ContentServerData,
  Option,
  ContentAddData,
  ContentEditData,
  ContentPublishOption,
} from '#/types';
import ApolloClient, { Queries } from './ApolloClient';

const ApiRequest = (() => {
  const firebase = Firebase;
  const firestore = firebase.firestore();
  const apolloClient = ApolloClient;

  return ({
    signInWithEmailAndPassword: async (email: string, password: string) => {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    },
    sendPasswordResetEmail: async (email: string) => {
      await firebase.auth().sendPasswordResetEmail(email);
    },
    signOut: async () => {
      await firebase.auth().signOut();
    },
    updatePassword: async (
      user: FirebaseUser,
      currentPassword: string,
      newPassword: string,
    ) => {
      if (user.email == null) {
        return;
      }

      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await user.reauthenticateWithCredential(credential);
      await user.updatePassword(newPassword);
    },
    getIsTutor: async (uid: string): Promise<boolean> => {
      try {
        const user = await firestore.doc(`users/${uid}`).get();
        const userType = user.data()?.messengerUserType;
        if (userType == null) {
          throw new Error('Undefined user');
        }

        if (userType === 'tutor') {
          return true;
        }

        return false;
      } catch (err) {
        throw new Error('[getIsTutor] No user in users. Please log in redkiwi-app first');
      }
    },
    getSendBirdToken: async (uid: string) => {
      const userDoc = await firestore.doc(`users/${uid}`).get();
      return userDoc.data()?.sendBirdToken;
    },
    getMessengerContentTopics: async (): Promise<Option<string>[]> => {
      const topicSnapshot = await firestore.collection('messengerTopics').get();
      return topicSnapshot.docs.map(
        (doc) => ({ value: doc.id, label: doc.data().topicText }),
      );
    },
    getMeesengerContentLevels: async (): Promise<Option<string>[]> => {
      const levelSnapshot = await firestore.collection('messengerContentLevels').get();
      return levelSnapshot.docs.map(
        (doc) => ({ value: doc.id, label: doc.data().levelText }),
      );
    },
    getMessengerContentConfigs: async () => {
      const [topics, levels] = await Promise.all([
        ApiRequest.getMessengerContentTopics(),
        ApiRequest.getMeesengerContentLevels(),
      ]);

      return { topics, levels };
    },
    getContents: async (): Promise<ContentServerData[] | null> => {
      const { data: { messengerContents: { records } } } = await apolloClient.query({
        query: Queries.GET_CONTENTS,
        fetchPolicy: 'network-only',
      });
      return records;
    },
    getContent: async (docId: string): Promise<ContentServerData> => {
      const { data: { messengerContent } } = await apolloClient.query({
        query: Queries.GET_CONTENT,
        variables: {
          input: {
            id: docId,
          },
        },
      });

      return messengerContent;
    },
    createContent: async (contentData: ContentAddData): Promise<void> => {
      await apolloClient.mutate({
        mutation: Queries.CREATE_CONTENT,
        variables: {
          input: {
            topicId: contentData.topic,
            levelId: contentData.level,
            published: contentData.published === ContentPublishOption.PUBLISHED,
            question: contentData.question,
          },
        },
      });
    },
    updateContent: async (contentData: ContentEditData): Promise<void> => {
      await apolloClient.mutate({
        mutation: Queries.UPDATE_CONTENT,
        variables: {
          input: {
            id: contentData.id,
            topicId: contentData.topic,
            levelId: contentData.level,
            published: contentData.published === ContentPublishOption.PUBLISHED,
            question: contentData.question,
          },
        },
      });
    },
    deleteContent: async (contentData: ContentEditData): Promise<void> => {
      await apolloClient.mutate({
        mutation: Queries.DELETE_CONTENT,
        variables: {
          input: {
            id: contentData.id,
          },
        },
      });
    },
  });
}
)();

export default ApiRequest;
