import {
  useMemo,
  useCallback,
} from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { useAuthContext } from '#/contexts/AuthContext';
import Colors from '#/styles/colors';
import JSUtility from '#/util/JSUtility';
import dayjs from '#/modules/ExtendedDayjs';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    padding: '20px 30px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  roleText: {
    fontWeight: 'bold',
  },
  authInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '0 10px',
  },
  notice: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  divider: {
    color: Colors.black,
  },
});

interface HeaderProps {
  isTutor: boolean;
}

const Header = ({
  isTutor,
}: HeaderProps): JSX.Element => {
  const classes = useStyles();
  const { user, logout, timeZone } = useAuthContext();

  // KO_KR 24:00 ~ 04:59(KST, GMT+9) in UTC, 15:00 ~ 19:59 (UTC, GMT+0)
  const [UTC_FEEDBACK_START, UTC_FEEDBACK_END] = useMemo(
    () => {
      const [UTC_FEEDBACK_START_DATE, UTC_FEEDBACK_END_DATE] = JSUtility.getTutorPeriod();
      return [dayjs(UTC_FEEDBACK_START_DATE), dayjs(UTC_FEEDBACK_END_DATE)];
    }, [],
  );

  const onClickLogout = useCallback((e) => {
    e.preventDefault();
    if (logout == null) {
      return;
    }

    logout();
  }, [logout]);

  return (
    <header className={classes.header}>
      <div>
        <Typography className={classes.roleText}>
          {`RedKiwi Messenger\n${isTutor ? 'Tutor' : 'Content Manager'} Page`}
        </Typography>
      </div>
      <div className={classes.authInfo}>
        {isTutor && (
          <Typography className={classes.notice}>
            {`Recommended Feedback time ${UTC_FEEDBACK_START.tz(timeZone).format('HH:mm')} - ${UTC_FEEDBACK_END.tz(timeZone).format('HH:mm')}(${UTC_FEEDBACK_START.tz(timeZone).format('z')})`}
          </Typography>
        )}
        <Typography>{user?.email}</Typography>
        <Divider className={classes.divider} flexItem orientation="vertical" />
        <Button fullWidth onClick={onClickLogout}>
          <Typography>
            Logout
          </Typography>
        </Button>
      </div>
    </header>
  );
};

export default Header;
