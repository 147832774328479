import {
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  ContentAddData,
  ContentPublishOption,
  Option,
  OptionsToRender,
} from '#/types';
import ContentAddForm from '#/components/contents/ContentAddForm';
import Loading from '#/components/Loading';
import ApiRequest from '#/modules/ApiRequest';
import dayjs from '#/modules/ExtendedDayjs';
import CustomPage from '#/components/CustomPage';
import {
  useAlertContext,
  Severity,
} from '#/contexts/AlertContext';

const useStyles = makeStyles({
  loading: {
    display: 'flex',
  },
  buttonGroup: {
    display: 'flex',
    gap: '1rem',
  },
  button: {
    width: '10rem',
    padding: '1rem 0',
  },
});

const contentStatusRadiosItems: Option<ContentPublishOption>[] = [
  { value: ContentPublishOption.DRAFT, label: 'Draft' },
  { value: ContentPublishOption.PUBLISHED, label: 'Published' },
];

const ContentAddPage = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { openAlert } = useAlertContext();

  const [contentAddData, setContentAddData] = useState<ContentAddData>({
    createdAt: dayjs(),
    published: ContentPublishOption.DRAFT,
    topic: '',
    level: '',
    question: '',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState<OptionsToRender>({
    topic: [{ value: '', label: '' }],
    level: [{ value: '', label: '' }],
    status: contentStatusRadiosItems,
  });

  const fetchOptions = useCallback(async () => {
    const { topics, levels } = await ApiRequest.getMessengerContentConfigs();
    setOptions((prevOptions) => ({
      ...prevOptions,
      topic: topics,
      level: levels,
    }));
    setContentAddData(
      (prevContentAddData) => ({
        ...prevContentAddData,
        topic: topics[0].value,
        level: levels[0].value,
      }),
    );
  }, []);

  useEffect(() => {
    const getItems = async () => {
      setIsLoading(true);
      try {
        await fetchOptions();
      } catch (err) {
        openAlert(Severity.ERROR, err.message);
      } finally {
        setIsLoading(false);
      }
    };

    getItems();
  }, [openAlert, fetchOptions]);

  const onClickCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onClickSave = useCallback(async () => {
    setIsLoading(true);
    let alertMessage = '';
    try {
      await ApiRequest.createContent(contentAddData);
      alertMessage = 'Content has been added!';
    } catch (err) {
      alertMessage = err;
    } finally {
      openAlert(Severity.ERROR, alertMessage);
      history.goBack();
    }
  }, [openAlert, contentAddData, history]);

  const onChangeContentAddData = useCallback(
    (e: ChangeEvent<{ name: string; value: unknown }>) => {
      const { name, value } = e.target;
      setContentAddData(
        (prevContentAddData) => ({ ...prevContentAddData, [name]: value }),
      );
    }, [],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CustomPage pathname="Contents/Add content">
      <ContentAddForm
        data={contentAddData}
        onChangeData={onChangeContentAddData}
        optionsToRender={options}
      />
      <div className={classes.buttonGroup}>
        <Button
          onClick={onClickSave}
          className={classes.button}
          variant="outlined"
          color="primary"
        >
          <Typography variant="button">SAVE</Typography>
        </Button>
        <Button
          onClick={onClickCancel}
          className={classes.button}
          variant="outlined"
          color="secondary"
        >
          <Typography variant="button">CANCEL</Typography>
        </Button>
      </div>
    </CustomPage>
  );
};

export default ContentAddPage;
