import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Colors from '#/styles/colors';
import {
  ChatMessageProps,
} from '#/types/index';
import ChatPaper from './ChatPaper';

type FeedbackDMPData = { dmp: [number, string][], explanation: string };

const useStyles = makeStyles({
  indicatorText: {
    fontWeight: 'bold',
  },
  removedText: {
    color: Colors.red,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  addedText: {
    color: Colors.green,
  },
});

type ColorizedText = {
  text: string;
  className?: string;
};

type ColorizedTexts = {
  original: ColorizedText[];
  correction: ColorizedText[];
};

const FeedbackChatMessage = ({
  message,
}: ChatMessageProps): JSX.Element => {
  const classes = useStyles();

  const feedbackDMPData: FeedbackDMPData | undefined = useMemo(() => {
    const feedbackData = message.data;
    if (feedbackData.length === 0) {
      return undefined;
    }

    return JSON.parse(feedbackData).feedback;
  }, [message.data]);

  const colorizedFeedback = useMemo(() => {
    if (feedbackDMPData === undefined) {
      return undefined;
    }

    const colorizedFeedbackInfo = (
      feedbackDMPData.dmp.reduce<ColorizedTexts>((total, [status, text]) => {
        if (status === 0) { // No change
          total.original.push({ text });
          total.correction.push({ text });
        }
        if (status === -1) { // removed
          total.original.push({ text, className: classes.removedText });
        }
        if (status === 1) { // added
          total.correction.push({ text, className: classes.addedText });
        }

        return total;
      }, {
        original: [],
        correction: [],
      })
    );
    return colorizedFeedbackInfo;
  }, [classes, feedbackDMPData]);

  if (colorizedFeedback === undefined) {
    return (
      <ChatPaper>
        <Typography>
          {message.message}
        </Typography>
      </ChatPaper>
    );
  }

  return (
    <ChatPaper>
      <Typography className={classes.indicatorText}>
        [Original]
      </Typography>
      {colorizedFeedback.original.map(({ text, className }, index) => (
        // Since these Texts will not change dynamically, I disalbed ESLint rule.
        // eslint-disable-next-line react/no-array-index-key
        <Typography component="span" className={className} key={`original-${index}`}>
          {text}
        </Typography>
      ))}

      <Typography className={classes.indicatorText}>
        [Correction]
      </Typography>
      {colorizedFeedback.correction.map(({ text, className }, index) => (
        // Since these Texts will not change dynamically, I disalbed ESLint rule.
        // eslint-disable-next-line react/no-array-index-key
        <Typography component="span" className={className} key={`correction-${index}`}>
          {text}
        </Typography>
      ))}

      {(feedbackDMPData?.explanation != null && feedbackDMPData.explanation.length > 0)
        && (
          <>
            <Typography className={classes.indicatorText}>
              [Explanation]
            </Typography>
            <Typography component="span">
              {feedbackDMPData.explanation}
            </Typography>
          </>
        )}
    </ChatPaper>
  );
};

export default FeedbackChatMessage;
