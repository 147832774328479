/* eslint-disable max-len */
import {
  split as splitSentences,
  Syntax,
} from 'sentence-splitter';
import {
  diffWords,
  convertChangesToDMP,
} from 'diff';

import dayjs from '#/modules/ExtendedDayjs';
import {
  UTC_TIME_CONFIG,
} from '#/constants';

const JSUtility = {
  isProduction: (): boolean => window.location.host === 'messenger.redkiwiapp.com',
  isDevelopment: (): boolean => !JSUtility.isProduction(),
  normalizeSearch: (search: string): string => search.replace(/\s/g, '').toLowerCase(),
  separateSentences: (sentences: string): string[] => {
    const sentenceArray = (
      splitSentences(sentences)
        .filter((sentence) => sentence.type === Syntax.Sentence)
        .map((sentence) => sentence.raw)
    );
    return sentenceArray;
  },
  diffWordsAsDMP: (oldSentence: string, newSentence: string): [0 | 1 | -1, string][] => {
    const diffResult = diffWords(oldSentence, newSentence);
    const DMPData = convertChangesToDMP(diffResult);

    return DMPData;
  },
  replaceParamsToPathname: (
    path: string,
    params: Record<string, string | undefined>,
  ): string => {
    const pathname = Object
      .keys(params)
      .reduce((totalPath, param) => totalPath.replace(`:${param}`, params[param] ?? ''), path);
    return pathname;
  },
  getBreadcrumbsFromPathname: (pathname: string): { name: string, path: string }[] => {
    const names = pathname.split('/').filter((value) => value.length > 0);

    let previousPath = '';
    const breadcrumbsData = names.reduce((breadcrumbs: Record<'name' | 'path', string>[], name) => {
      const path = `${previousPath}/${name}`;
      breadcrumbs.push({ name, path });

      previousPath = path;
      return breadcrumbs;
    }, []);
    return breadcrumbsData;
  },
  getCompleteRate: (sent: number, answered: number): number => {
    if (sent === 0) {
      return 0;
    }

    const percentage = (answered / sent) * 100;

    return (
      percentage > 0 && percentage < 1
        ? 1
        : Math.floor(percentage)
    );
  },
  convertOptionsArrayToOptionMap: (options: Record<'value' | 'label', string>[]): Map<string, string> => {
    const optionMap = options.reduce(
      (newOptionMap, { value, label }) => {
        newOptionMap.set(value, label);
        return newOptionMap;
      }, new Map<string, string>(),
    );

    return optionMap;
  },
  getTutorPeriod: (date?: Date): [Date, Date] => {
    // If date is undefined, get now.
    const targetUTCDate = dayjs(date).utc();

    const UTCFeedbackStart = targetUTCDate.hour(UTC_TIME_CONFIG.feedbackStartHour).minute(0).second(0).millisecond(0);
    const UTCFeedbackEnd = UTCFeedbackStart.add(5, 'hours').subtract(1, 'second');

    // 12:00:00 ~ 04:59:59
    return [UTCFeedbackStart.toDate(), UTCFeedbackEnd.toDate()];
  },
  getIsTutorPeriod: (date?: Date): boolean => {
    const [UTCFeedbackStart, UTCFeedbackEnd] = JSUtility.getTutorPeriod(date);
    const dayjsDate = dayjs(date);
    return dayjsDate.isSameOrAfter(UTCFeedbackStart) && dayjsDate.isSameOrBefore(UTCFeedbackEnd);
  },
  getIsStudentPeriod: (date?: Date): boolean => !JSUtility.getIsTutorPeriod(date),
  getUnitPeriod: (date?: Date): [Date, Date] => {
    /**
     * Unit meaning
     * 1. Send question to user by redkiwi-server scheduled function. (CustomMessageType.QUESTION)
     * 2. Send answer by student reply on question during. (CustomMessageType.ANSWER)
     * 3. Send feedback by tutor reply on student's answer during (CustomMessageType.REVIEW, CustomMessageType.FEEDBACK)
     * KST)
     * 1. 05:00
     * 2. 05:00 - 24:00
     * 3. 24:00 - 29:00(05:00)
     * UTC)
     * 1. 20:00
     * 2. 20:00 - 39:00(15:00)
     * 3. 39:00(15:00) - 45:00(21:00)
     * */
    const targetUTCDate = dayjs(date).utc();
    const targetUTCHour = targetUTCDate.hour(); // 0~23

    const isFirstDay = targetUTCHour >= UTC_TIME_CONFIG.unitStartHour; // 20:00 ~ 23:59

    const UTCStartDayDate = isFirstDay
      ? targetUTCDate
      : targetUTCDate.subtract(1, 'day');

    const UTCUnitStartDate = UTCStartDayDate.hour(UTC_TIME_CONFIG.unitStartHour).minute(0).second(0);

    return [
      UTCUnitStartDate.toDate(),
      UTCUnitStartDate.add(1, 'day').subtract(1, 'seconds').toDate(),
    ];
  },
  getIsSameUnitPeriod: (date1: Date, date2: Date): boolean => {
    const [UTCUnitStartDate, UTCUnitEndDate] = JSUtility.getUnitPeriod(date1);
    const date2Date = dayjs(date2);

    return date2Date.isAfter(UTCUnitStartDate) && date2Date.isBefore(UTCUnitEndDate);
  },
};

export default JSUtility;
