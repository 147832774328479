import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  ChatMessageAlign,
  ChatMessageProps,
} from '#/types/index';
import ChatPaper from './ChatPaper';
import Colors from '#/styles/colors';

const useStyles = makeStyles({
  answerPaper: {
    background: Colors.mint,
  },
});

const AnswerChatMessage = ({ message }: ChatMessageProps): JSX.Element => {
  const classes = useStyles();

  return (
    <ChatPaper
      align={ChatMessageAlign.LEFT}
      className={classes.answerPaper}
    >
      <Typography>
        {message.message}
      </Typography>
    </ChatPaper>
  );
};

export default AnswerChatMessage;
