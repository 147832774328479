import {
  useMemo,
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import {
  CustomMessageType,
  ChatMessageAlign,
  ChatMessageProps,
} from '#/types';
import AnswerChatMessage from './AnswerChatMessage';
import FeedbackChatMessage from './FeedbackChatMessage';
import BasicChatMessage from './BasicChatMessage';
import QuestionChatMessage from './QuestionChatMessage';
import Colors from '#/styles/colors';
import dayjs from '#/modules/ExtendedDayjs';

const useStyles = makeStyles({
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0.3rem 0',
  },
  senderText: {
    fontWeight: 'bold',
  },
  alignLeft: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  alignRight: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  sentAtText: {
    color: Colors.darkGray,
  },
  alignLeftSentAt: {
    order: 1,
  },
  alignRightSentAt: {
    order: -1,
  },
  chatMessage: {
    display: 'flex',
    flexGrow: 1,
    alignSelf: 'stretch',
    alignItems: 'flex-end',
    gap: 10,
  },
});

const ChatMessage = ({ message }: ChatMessageProps): JSX.Element => {
  const classes = useStyles();

  const ChatMessageByCustomType = useMemo(() => {
    switch (message.customType) {
      case CustomMessageType.ANSWER:
        return AnswerChatMessage;
      case CustomMessageType.QUESTION:
        return QuestionChatMessage;
      case CustomMessageType.FEEDBACK:
        return FeedbackChatMessage;
      default:
        return BasicChatMessage;
    }
  }, [message]);

  const senderTextToShow = useMemo(() => {
    switch (message.customType) {
      case CustomMessageType.ANSWER:
        return message.sender.nickname;
      case CustomMessageType.QUESTION:
      case CustomMessageType.TEXT:
        return 'System';
      default:
        return undefined;
    }
  }, [message]);

  const chatMessageAlign = useMemo(() => {
    switch (message.customType) {
      case CustomMessageType.ANSWER:
        return ChatMessageAlign.LEFT;
      default:
        return ChatMessageAlign.RIGHT;
    }
  }, [message]);

  const formatedSentAt = useMemo(() => dayjs(message.createdAt).format('hh:mm A'), [message]);

  return (
    <div
      className={[
        classes.messageContainer,
        chatMessageAlign === ChatMessageAlign.LEFT
          ? classes.alignLeft
          : classes.alignRight,
      ].join(' ')}
    >
      {senderTextToShow != null
        && (
          <Typography className={classes.senderText}>
            {senderTextToShow}
          </Typography>
        )}
      <div className={
        [
          classes.chatMessage,
          chatMessageAlign === ChatMessageAlign.LEFT
            ? classes.alignLeft
            : classes.alignRight,
        ].join(' ')
      }
      >
        <ChatMessageByCustomType message={message} />
        <Typography
          className={
            [
              classes.sentAtText,
              chatMessageAlign === ChatMessageAlign.LEFT
                ? classes.alignLeftSentAt
                : classes.alignRightSentAt,
            ].join(' ')
          }
          variant="body2"
        >
          {formatedSentAt}
        </Typography>
      </div>
    </div>
  );
};

export default ChatMessage;
