import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Option } from '#/types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    width: '50%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    fontSize: '1rem',
  },
}));

interface ContentSelectProps {
  name: string;
  options: Option<string>[];
  value: string;
}

const ContentSelect = ({
  name,
  options,
  value,
}: ContentSelectProps): JSX.Element => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        native
        id="content-select"
        name={name}
        value={value}
      >
        {options.map((option) => (
          <option
            className={classes.option}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default ContentSelect;
