import {
  useCallback,
  useState,
} from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import timezones from 'timezones-list';

import { useAuthContext } from '#/contexts/AuthContext';

const useStyles = makeStyles({
  root: {
    width: '30rem',
    margin: '1rem 0rem',
  },
  buttonGroup: {
    display: 'flex',
    gap: '1.5rem',
    margin: '1rem 0rem',
  },
  button: {
    width: '10rem',
    padding: '1rem 2rem',
  },
});

const TIME_ZONES = timezones;

const ProfileTimeZone = (): JSX.Element => {
  const classes = useStyles();
  const { timeZone, setTimeZone } = useAuthContext();
  const [currentTimeZone, setCurrentTimeZone] = useState(timeZone);

  const onChangeTimeZone = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentTimeZone(event.target.value as string);
  }, []);

  const onClickUpdate = useCallback(() => {
    if (setTimeZone == null) {
      return;
    }
    setTimeZone(currentTimeZone);
  }, [setTimeZone, currentTimeZone]);

  return (
    <FormControl required variant="outlined" className={classes.root}>
      <InputLabel id="timezone-select-label">
        <Typography component="span">
          Time Zone
        </Typography>
      </InputLabel>
      <Select
        native
        labelId="timezone-select-label"
        id="timezone-select"
        label="Time Zone"
        value={currentTimeZone}
        onChange={onChangeTimeZone}
      >
        {TIME_ZONES.map(({ tzCode, name }) => (
          <option value={tzCode} key={tzCode}>
            {name}
          </option>
        ))}
      </Select>
      <div className={classes.buttonGroup}>
        <Button
          className={classes.button}
          onClick={onClickUpdate}
          variant="outlined"
          color="primary"
        >
          <Typography>Update</Typography>
        </Button>
      </div>
    </FormControl>
  );
};

export default ProfileTimeZone;
