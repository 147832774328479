import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  ContentEditData,
  ContentPublishOption,
  OptionsToRender,
  Option,
} from '#/types';
import ContentEditForm from '#/components/contents/ContentEditForm';
import Loading from '#/components/Loading';
import ApiRequest from '#/modules/ApiRequest';
import dayjs from '#/modules/ExtendedDayjs';
import JSUtility from '#/util/JSUtility';
import CustomPage from '#/components/CustomPage';
import {
  useAlertContext,
  Severity,
} from '#/contexts/AlertContext';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: 'flex',
    gap: '1rem',
  },
  button: {
    width: '10rem',
    padding: '1rem 0',
  },
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    marginLeft: 'auto',
  },
  cancelButton: {
    color: theme.palette.grey[500],
    borderColor: theme.palette.grey[500],
  },
}));

const contentStatusRadiosItems: Option<ContentPublishOption>[] = [
  { value: ContentPublishOption.DRAFT, label: 'Draft' },
  { value: ContentPublishOption.PUBLISHED, label: 'Published' },
];

const ContentEditPage = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { openAlert } = useAlertContext();
  const { contentId } = useParams<{ contentId?: string }>();

  // TODO: Can use state passed by router instead of fetching same data.
  // const { state } = useLocation<{ contentData?: ContentTableData }>();
  const [contentEditData, setContentEditData] = useState<ContentEditData>({
    id: '',
    createdAt: dayjs(),
    updatedAt: dayjs(),
    published: ContentPublishOption.DRAFT,
    topic: '',
    level: '',
    questionNumber: 0,
    question: '',
    sent: 0,
    answered: 0,
    completed: 0,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState<OptionsToRender>({
    topic: [{ value: '', label: '' }],
    level: [{ value: '', label: '' }],
    status: contentStatusRadiosItems,
  });

  const fetchContent = useCallback(async () => {
    if (contentId == null) return;

    const content = await ApiRequest.getContent(contentId);
    if (content == null) {
      openAlert(Severity.ERROR, 'Invalid Content ID');
      history.goBack();
      return;
    }

    setContentEditData({
      id: content.id,
      createdAt: dayjs(content.createdAt),
      updatedAt: dayjs(content.updatedAt),
      level: content.levelId,
      topic: content.topicId,
      published: content.published
        ? ContentPublishOption.PUBLISHED
        : ContentPublishOption.DRAFT,
      question: content.question,
      questionNumber: content.contentNumber ?? -1,
      sent: content.sentCount,
      answered: content.answeredCount,
      completed: JSUtility.getCompleteRate(content.sentCount, content.answeredCount),
    });
  }, [openAlert, contentId, history]);

  const fetchItems = useCallback(async () => {
    const { topics, levels } = await ApiRequest.getMessengerContentConfigs();
    setOptions((prevOptions) => ({
      ...prevOptions,
      topic: topics,
      level: levels,
    }));
  }, []);

  useEffect(() => {
    const getContentData = async () => {
      setIsLoading(true);
      try {
        await fetchItems();
        await fetchContent();
      } catch (err) {
        openAlert(Severity.ERROR, err);
      } finally {
        setIsLoading(false);
      }
    };

    getContentData();
  }, [openAlert, fetchItems, fetchContent]);

  const onClickSave = useCallback(async () => {
    setIsLoading(true);
    let alertMessage = '';
    try {
      await ApiRequest.updateContent(contentEditData);
      alertMessage = 'Content has been updated!';
    } catch (err) {
      alertMessage = err;
    } finally {
      openAlert(Severity.ERROR, alertMessage);
      window.location.reload();
    }
  }, [openAlert, contentEditData]);

  const onClickDelete = useCallback(async () => {
    // TODO: Replace Modal things
    // eslint-disable-next-line no-restricted-globals
    const agree = confirm('Delete This Content?');

    if (!agree) {
      return;
    }

    let alertMessage = '';
    try {
      setIsLoading(true);
      await ApiRequest.deleteContent(contentEditData);
      alertMessage = 'Content is Deleted';
      history.goBack();
    } catch (err) {
      openAlert(Severity.ERROR, err);
      alertMessage = err;
    } finally {
      setIsLoading(false);
      openAlert(Severity.ERROR, alertMessage);
    }
  }, [openAlert, contentEditData, history]);

  const onClickCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onChangeContentEditData = useCallback(
    (e: ChangeEvent<{ name: string; value: unknown }>) => {
      const { name, value } = e.target;
      setContentEditData(
        (prevContentEditData) => ({ ...prevContentEditData, [name]: value }),
      );
    }, [],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CustomPage pathname="Contents/Edit Content">
      <ContentEditForm
        data={contentEditData}
        onChangeData={onChangeContentEditData}
        optionsToRender={options}
      />
      <div className={classes.buttonGroup}>
        <Button
          onClick={onClickSave}
          className={classes.button}
          variant="outlined"
          color="primary"
        >
          <Typography variant="button">SAVE</Typography>
        </Button>
        <Button
          onClick={onClickCancel}
          className={[classes.button, classes.cancelButton].join(' ')}
          variant="outlined"
        >
          <Typography variant="button">CANCEL</Typography>
        </Button>
        <Button
          onClick={onClickDelete}
          className={[classes.button, classes.deleteButton].join(' ')}
          variant="outlined"
        >
          <Typography variant="button" color="error">DELETE</Typography>
        </Button>
      </div>
    </CustomPage>
  );
};

export default ContentEditPage;
