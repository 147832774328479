import {
  useMemo,
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  Link as RouterLink,
} from 'react-router-dom';
import clsx from 'clsx';

import Utility from '#/util/JSUtility';
import Colors from '#/styles/colors';

const useStyles = makeStyles({
  textBase: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  disabledLink: {
    pointerEvents: 'none',
    textDecoration: 'none',
  },
  enabledLink: {
    color: Colors.darkGray,
  },
  menuTitle: {
    fontSize: '2rem',
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface CustomBreadcrumbsProps {
  pathname: string;
  rightComponent?: JSX.Element;
}

const CustomBreadcrumbs = ({
  pathname,
  rightComponent,
}: CustomBreadcrumbsProps): JSX.Element => {
  const classes = useStyles();
  const breadcrumbs = useMemo(() => Utility.getBreadcrumbsFromPathname(pathname), [pathname]);
  const menuTitle = useMemo(() => {
    if (breadcrumbs.length === 0) {
      return '';
    }

    const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
    return lastBreadcrumb.name;
  }, [breadcrumbs]);

  return (
    <nav className={classes.nav}>
      <div>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs.map(({ name, path }, index, { length }) => {
            const isLastItem = length === index + 1;
            return (
              <Link
                component={RouterLink}
                to={path}
                className={isLastItem ? classes.disabledLink : classes.enabledLink}
                key={path}
              >
                <Typography className={classes.textBase} color={isLastItem ? 'textPrimary' : 'inherit'}>
                  {name}
                </Typography>
              </Link>
            );
          })}
        </Breadcrumbs>
        <Typography component="h3" className={clsx(classes.textBase, classes.menuTitle)}>
          {menuTitle}
        </Typography>
      </div>
      <div className={classes.rightComponent}>
        {rightComponent}
      </div>
    </nav>
  );
};

export default CustomBreadcrumbs;
