import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import {
  ContentPublishOption,
  Option,
} from '#/types';

interface ContentRadioGroupProps {
  name: string;
  options: Option<ContentPublishOption>[];
  value: ContentPublishOption;
}

const ContentRadioGroup = ({
  name,
  options,
  value,
}: ContentRadioGroupProps): JSX.Element => (
  <RadioGroup
    row
    aria-label="published"
    name={name}
    value={value}
  >
    {options.map((option) => (
      <FormControlLabel
        key={option.label}
        value={option.value}
        control={(
          <Radio
            name={name}
            color="primary"
          />
        )}
        label={option.label}
      />
    ))}
  </RadioGroup>
);

export default ContentRadioGroup;
