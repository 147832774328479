import { useLocation } from 'react-router-dom';

const useDisplayName = (): string => {
  const { search } = useLocation();
  const query = new URLSearchParams(search.slice(1)); // remove '?'
  const displayName = query.get('displayName');

  if (displayName == null) {
    throw new Error('No displayName query');
  }

  return displayName;
};

export default useDisplayName;
