import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ContentItem from './ContentItem';
import ContentRadioGroup from './ContentRadioGroup';
import ContentSelect from './ContentSelect';
import ContentInput from './ContentInput';
import {
  OptionsToRender,
  ContentEditData,
} from '#/types';

const useStyles = makeStyles({
  root: {
    margin: '1rem 0',
  },
});

interface ContentEditFormProps {
  data: ContentEditData
  onChangeData: (e) => void;
  optionsToRender: OptionsToRender;
}

const ContentEditForm = ({
  data,
  onChangeData,
  optionsToRender,
}: ContentEditFormProps): JSX.Element => {
  const classes = useStyles();

  return (
    <form onChange={onChangeData}>
      <Grid className={classes.root} container spacing={2}>
        <ContentItem
          label="Content ID"
          rightComponent={data.id}
        />
        <ContentItem
          label="Created"
          rightComponent={data.createdAt.format('YYYY-MM-DD HH:mm:ss')}
        />
        <ContentItem
          label="Last Edited"
          rightComponent={data.updatedAt.format('YYYY-MM-DD HH:mm:ss')}
        />
        <ContentItem
          label="Status"
          rightComponent={(
            <ContentRadioGroup
              name="published"
              options={optionsToRender.status}
              value={data.published}
            />
          )}
        />
        <ContentItem
          label="Topic"
          rightComponent={(
            <ContentSelect
              name="topic"
              options={optionsToRender.topic}
              value={data.topic}
            />
          )}
        />
        <ContentItem
          label="Level"
          rightComponent={(
            <ContentSelect
              name="level"
              options={optionsToRender.level}
              value={data.level}
            />
          )}
        />
        <ContentItem
          label="Question #"
          rightComponent={1}
        />
        <ContentItem
          label="Question"
          rightComponent={(
            <ContentInput
              name="question"
              value={data.question}
            />
          )}
        />
        <ContentItem
          label="Sent"
          rightComponent={data.sent}
        />
        <ContentItem
          label="Answered"
          rightComponent={data.answered}
        />
        <ContentItem
          label="Complete"
          rightComponent={`${data.answered}% (Answered / Sent)`}
        />
      </Grid>
    </form>
  );
};

export default ContentEditForm;
