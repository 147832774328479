import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import Login from '../pages/LoginPage';
import ForgotPassword from '../pages/ForgotPasswordPage';
import { ROUTE_NAMES } from '../constants';

const PublicRouter = (): JSX.Element => (
  <Switch>
    <Route exact path={ROUTE_NAMES.LOGIN} component={Login} />
    <Route exact path={ROUTE_NAMES.FORGOT_PASSWORD} component={ForgotPassword} />
    <Redirect to={ROUTE_NAMES.LOGIN} />
  </Switch>
);

export default PublicRouter;
